import { Injectable } from '@angular/core';
import { ETimers } from '../consts/enums';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class NoticeService {

  constructor(
    private snackBar: MatSnackBar,
  ) { }

  public showSnackBar = (
    text: string,
    duration = ETimers.Medium,
    error = false
  ): void => {
    const options = {
      duration: duration,
      panelClass: (error
          ? 'snackBarError'
          : 'snackBarOk'
      ),
    };

    this.snackBar.open(text, '', options);
  }
}
