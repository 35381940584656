<div class="dialog full-height">
  <h1
    mat-dialog-title
    class="dialog-title"
  >
    {{data.title}}
    <button
      mat-icon-button
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </h1>

  <div
    mat-dialog-content
    class="dialog-content"
  >
    <div class="server-message-container">
      <mat-progress-bar
        [value]="progressBarValue"
        color="primary"
      ></mat-progress-bar>
      <div class="terminal-wrapper">
        <div #terminal>
          <div
            class="message"
            *ngFor="let message of messages"
            [ngClass]="message.class"
            [innerHTML]="message.text"
          ></div>
          <div
            *ngIf="!isAsyncImport && readingIsFinished"
            style="margin-top: 10px;"
          >
            Done...
          </div>
        </div>
      </div>
      <a #solutionFile></a>
    </div>
  </div>

  <div
    mat-dialog-actions
    class="button-container"
  >
    <button
      *ngIf="data.allocateOrderToManifest"
      mat-button
      class="mat-accent-300-bg"
      [disabled]="!allocationResult"
      (click)="allocate()"
    >Save</button>

    <button
      mat-button
      class="mat-grey-200-bg"
      mat-dialog-close
    >Close</button>

    <span *ngIf="!asyncProcessIsFinished && !readingIsFinished">
      You can close the import popup. The process will continue in the background
    </span>

    <button
      *ngIf="!asyncProcessIsFinished && !readingIsFinished && !cancelled"
      mat-button
      style="margin-left: auto"
      color="warn"
      (click)="cancelImport()"
    >Cancel</button>

    <!--     <span *ngIf="data.safeCloseable && done">
      The import has been finished
    </span>
 -->
  </div>
</div>