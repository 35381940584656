import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateRangeFilterComponent } from './components/date-range-filter/date-range-filter.component';
import { DateRangeModule } from 'app/shared/modules/date-range/date-range.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [
    DateRangeFilterComponent
  ],
  imports: [
    CommonModule,
    DateRangeModule,
    DateRangeModule,
    MatFormFieldModule,
    MatInputModule
  ],
  exports: [
    DateRangeFilterComponent
  ]
})
export class DateRangeFilterModule { }
