import { Inject, Injectable, LOCALE_ID, NgModule, Provider } from '@angular/core';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatDateFormats,
  MatNativeDateModule,
} from '@angular/material/core';
import { CookieService } from 'ngx-cookie-service';
import gb from '@angular/common/locales/en-GB';
import us from '@angular/common/locales/es-US';
import { FormatWidth, getLocaleDateFormat, registerLocaleData } from '@angular/common';
import { ECookies } from 'app/consts/enums';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MatMomentDateAdapterOptions,
  MatMomentDateModule,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';

registerLocaleData(gb);
registerLocaleData(us);

@Injectable({ providedIn: 'root' })
export class LocaleService {
  constructor(private cookieService: CookieService) {}

  getLocaleId(): string {
    return this.cookieService.get(ECookies.Locale)?.trim() || 'en-GB';
  }

  getMatDateFormat(): Partial<MatDateFormats> {
    const dateFormat = getLocaleDateFormat(this.getLocaleId(), FormatWidth.Short).toUpperCase();

    return {
      display: {
        dateInput: dateFormat,
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMM YYYY',
      },
    };
  }
}

@NgModule({
  imports: [MatMomentDateModule, MatNativeDateModule],
  providers: [
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    {
      provide: LOCALE_ID,
      useFactory: (s: LocaleService) => s.getLocaleId(),
      deps: [LocaleService],
    },
    {
      provide: MAT_DATE_LOCALE,
      useFactory: (s: LocaleService) => s.getLocaleId(),
      deps: [LocaleService],
    },
    {
      provide: MAT_DATE_FORMATS,
      useFactory: (s: LocaleService) => s.getMatDateFormat(),
      deps: [LocaleService],
    },
    { provide: DateAdapter, useClass: MomentDateAdapter },
  ],
})
export class LocaleModule {}
