<ng-container *ngIf="layout === 'vertical'">
  <div class="navbar-vertical" fusePerfectScrollbar>
    <div class="navbar-header">
      <div class="logo branding">
        <img
          class="logo-icon"
          src="assets/branding/default/logo.png"
          onerror="this.onerror=null; this.src='assets/branding/logo.svg'"      
        >
      </div>
      <button
        mat-button
        fxHide.lt-lg
        class="toggle-button-navbar mat-icon-button"
        (click)="toggleSidebarFolded('navbar')"
      ><mat-icon>menu</mat-icon></button>
      <button
        mat-button
        fxHide.gt-md
        class="toggle-button-navbar mat-icon-button"
        (click)="toggleSidebarOpened('navbar')"
      ><mat-icon>arrow_back</mat-icon></button>
    </div>

    <div class="navbar-content" fusePerfectScrollbar>
      <fuse-navigation [navigation]="sharedService.navigation" layout="vertical"></fuse-navigation>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="layout === 'horizontal'">
  <div class="navbar-horizontal">
    <fuse-navigation [navigation]="sharedService.navigation" layout="horizontal"></fuse-navigation>
  </div>
</ng-container>
