import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatDialogModule } from '@angular/material/dialog';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';
import { AnalyticsModule } from './modules/analytics/analytics.module';
import * as Sentry from '@sentry/angular-ivy';

import { LOGIN_ROUTE, MAIN_ROUTE, ONBOARDING_ROUTE, ROUTES } from 'app/consts/constants';
import { EModuleIDs } from 'app/consts/enums';

import { fuseConfig } from 'app/fuse-config';
import { AppComponent } from 'app/app.component';
import { OnboardingStepperComponent } from 'app/main/content/onboarding/steps/onboarding-stepper.component';

import { Authguard } from 'app/authguard';
import { UnsavedGuard } from 'app/unsaved.guard';

import { MatSnackBarModule } from '@angular/material/snack-bar';
import { CommonModule, DatePipe } from '@angular/common';
import { HttpInterceptorApi } from './services/api.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { LocaleModule } from './services/locale.service';


const appRoutes: Routes = [
  {
    path: MAIN_ROUTE,
    canActivate: [Authguard],
    loadChildren: () => import('./main/main.module').then(m => m.FuseMainModule),
  },
  {
    path: '',
    redirectTo: `${MAIN_ROUTE}/${ROUTES[EModuleIDs.Manifests]}`,
    pathMatch: 'full',
  },
  {
    path: LOGIN_ROUTE,
    loadChildren: () => import('./main/login/login.module').then(m => m.LoginModule),
  },
  {
    path: ONBOARDING_ROUTE,
    canActivate: [Authguard],
    loadChildren: () =>
      import('./main/content/onboarding/steps/onboarding-stepper.module').then(
        m => m.OnboardingStepperModule,
      ),
    component: OnboardingStepperComponent,
  },
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatAutocompleteModule, // it has to be here besides child module imports (at least for material 14)
    HttpClientModule,
    RouterModule.forRoot(appRoutes, {
      onSameUrlNavigation: 'reload',
      relativeLinkResolution: 'legacy',
    }),
    TranslateModule.forRoot(),
    // Fuse Main and Shared modules
    
    FuseModule.forRoot(fuseConfig),
    FuseSharedModule,
    TourMatMenuModule.forRoot(),
    AnalyticsModule,
    MatSnackBarModule,
    LocaleModule,
  ],
  exports: [MatDialogModule],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorApi,
      multi: true,
    },

    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    DatePipe,
    UnsavedGuard,
  ],
})
export class AppModule {}
