import { Component, Input, OnInit } from '@angular/core';
import { IMPORT_HISTORY_STATUSES_MAP } from 'app/consts/constants';
import { EImportStatus } from 'app/consts/enums';

@Component({
  selector: 'fuse-import-status-icon[status]',
  templateUrl: './import-status-icon.component.html',
  styleUrls: ['./import-status-icon.component.scss']
})
export class ImportStatusIconComponent implements OnInit {
  @Input() status: EImportStatus;

  readonly statusesInfo = IMPORT_HISTORY_STATUSES_MAP;

  constructor() { }

  ngOnInit(): void {
  }

  public isProgress = (): boolean => {
    return this.status === EImportStatus.Process;
  }
}
