import { Component, Input, OnInit } from '@angular/core';
import { EModuleIDs } from 'app/consts/enums';
import { Feedback } from '../../../../types/types';
import { SharedService } from 'app/services/shared.service';

@Component({
  selector: 'fuse-feedback-survey-item',
  templateUrl: './feedback-survey-item.component.html',
  styleUrls: ['./feedback-survey-item.component.scss']
})
export class FeedbackSurveyItemComponent implements OnInit {
  @Input() feedback: Feedback;

  public readonly ordersModule = EModuleIDs.Orders;

  constructor(
    public sharedService: SharedService,
  ) { }

  ngOnInit(): void {}

  public getBorderClass = (): string => {
    if (this.feedback.rating <= 2) {
      return 'border_red';
    } else if (this.feedback.rating >= 4) {
      return 'border_green';
    } else {
      return '';
    }
  }
}
