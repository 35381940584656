<div class="dialog">
  <h1 mat-dialog-title class="dialog-title">
    {{title}}
    <button mat-icon-button aria-label="close dialog" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </h1>

  <div
    *ngIf="isDateRange && data.type !== importOptions.Fresho"
    mat-dialog-content
    class="dialog-content"
  >
    <mat-form-field
      *ngIf="data.type === importOptions.MYOB"
      appearance="outline"
    >
      <mat-label>MYOB company</mat-label>
      <mat-select
        [(ngModel)]="myobCompany"
        placeholder="MYOB company"
      >
        <mat-option
          *ngFor="let company of myobCompanies"
          [value]="company.Uri"
        >{{company.Name}}</mat-option>
      </mat-select>
    </mat-form-field>

    <div class="checkbox-with-info">
      <mat-checkbox
        *ngIf="data.type === importOptions.OrderMentum"
        [(ngModel)]="createManifests"
        name="createManifests"
      >Create Manifests during import</mat-checkbox>
    </div>

    <mat-form-field
      *ngIf="data.type === importOptions.OrderMentum && 
        createManifests && 
        orderMentumPropertyList?.length"
      appearance="outline"
    >
      <mat-select 
        [(ngModel)]="filterProperty" 
        placeholder="Select Property Tag for Manifest Creation"
      >
        <mat-option *ngFor="let element of orderMentumPropertyList" value="{{element}}">
            {{element}}
        </mat-option>
      </mat-select>
    </mat-form-field>
 
    <div
      *ngIf="isDateRange"
      class="grid-2c small-gap"
    >
      <mat-form-field appearance="outline">
        <mat-label>From date</mat-label>
        <input
          matInput
          input="date"
          [(ngModel)]="fromDate"
          readonly
          [matDatepicker]="fromDatePicker"
          placeholder="From date"
        >
        <mat-datepicker-toggle
          matSuffix
          [for]="fromDatePicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #fromDatePicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>To date</mat-label>
        <input
          matInput
          input="date"
          [(ngModel)]="toDate"
          readonly
          [matDatepicker]="toDatePicker"
          placeholder="To date"
        >
        <mat-datepicker-toggle
          matSuffix
          [for]="toDatePicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #toDatePicker></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="checkbox-with-info">
      <mat-checkbox
        *ngIf="multiStageAvailable"
        [(ngModel)]="multiStage"
      >Multi-stage import</mat-checkbox>
    </div>

    <div class="checkbox-with-info">
      <mat-checkbox
        *ngIf="createManifestsAvailable"
        [(ngModel)]="createManifests"
      >Create manifests for imported orders</mat-checkbox>
    </div>
  </div>

  <div mat-dialog-actions>
    <button
      mat-button
      [disabled]="(isDateRange && !(fromDate && toDate)) || ((data.type === importOptions.MYOB) && !myobCompany)"
      class="mat-accent-300-bg"
      (click)="okClick()"
    >Start import</button>
    <button
      mat-button
      class="mat-grey-200-bg"
      mat-dialog-close
    >Cancel</button>
  </div>
</div>
