<mat-card [class]="getBorderClass()">
  <mat-card-title>
    <div class="card-title-item">
      <span class="title-text grey">Driver:</span>
      <span class="body-text">{{ feedback.driverName }}</span>
      <span class="title-text grey">Order:</span>
      <span class="body-text">
        <a
          class="title-link"
          [routerLink]="sharedService.linkToPage(ordersModule, feedback.orderId)"
        >
          #{{ feedback.orderId }}
          <mat-icon>open_in_new</mat-icon>
        </a>
      </span>
    </div>
    <div class="card-title-item">
      <fuse-rating-stars [rating]="feedback.rating"></fuse-rating-stars>
    </div>
  </mat-card-title>

  <mat-card-content>
    <div class="client">
      <mat-icon>person</mat-icon> <span>{{ feedback.clientName }}</span>
    </div>
    <div class="comment-title">Review</div>
    <div class="comment-body">
      {{ feedback.comments }}
    </div>
  </mat-card-content>
</mat-card>
