import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class QueryUtilsService {

  constructor() { }

  getPreparedQuery(endpoint: string, params: Record<string, any>): string {
    const keys: string[] = Object.keys(params);
    const queryParams: string[] = keys
      .filter(key => !!params[key])
      .map(key => `${key}=${params[key]}`);
    return `${endpoint}?${queryParams.join('&')}`;
  }
}
