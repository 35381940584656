import { ChangeDetectorRef, Inject, LOCALE_ID, OnDestroy, Pipe, PipeTransform } from "@angular/core";
import { RegionalPipe } from "./regional.pipe";
import { AccountConfigService } from "app/services/account-config.service";
import { formatNumber } from "@angular/common";
import { Subscription } from "rxjs";

@Pipe({
    name: 'regionalCurrency',
    pure: false
})
export class RegionalCurrencyPipe implements PipeTransform, OnDestroy {
    private readonly regionalPipe: RegionalPipe;
    private readonly updateAmountStr: Subscription;
    private amountStr: string = null;
    private previousAmount: number;

    constructor(
        @Inject(LOCALE_ID) private locale: string,
        accountConfigService: AccountConfigService,
        cdr: ChangeDetectorRef
    ) {
        this.regionalPipe = new RegionalPipe(accountConfigService, cdr);
        this.updateAmountStr = this.regionalPipe.updated$.subscribe(() => this.amountStr = null);
    }

    ngOnDestroy(): void {
        this.updateAmountStr.unsubscribe();
        this.regionalPipe.ngOnDestroy();
    }

    transform(amount: number) {
        if(this.previousAmount !== amount) {
          this.amountStr = null;
          this.previousAmount = amount;
        }
        try {
            this.amountStr ??= formatNumber(+amount, this.locale, '1.2-2');
        }
        catch {
          this.amountStr = "[format error]";
        }

        return this.regionalPipe.transform('currency') + this.amountStr;
    }

}
