import { Moment } from 'moment';

export interface FeedbackStats {
  chartValues: Array<{ name: string, value: number }>;
  indicatorSet: {
    averageRanging: number,
    positiveFeedback: number,
    negativeFeedback: number,
    feedbackWithComments: number
  };
}

export interface LineChartSeries {
  name: string;
  value: number;
}

export interface FeedbackTrends {
  chartSeries: Array<{ name: string, series: LineChartSeries[] }>;
}

export enum EFeedbackRequestType {
  Recent = 'Recent',
  Positive = 'Positive',
  Neutral = 'Neutral',
  Negative = 'Negative',
}

export enum EOrderType {
  Delivery = 'Delivery',
  Pickup = 'Pickup',
  PickupDelivery = 'PickupDelivery',
  Service = 'Service',
}

export interface FeedbackCustomerFilter {
  dateFrom?: Moment;
  dateTo?: Moment;
  feedbackRequestType?: EFeedbackRequestType;
  orderType?: string;
  driverId?: number | null;
  onlyWithComment?: boolean;
  offset?: number;
  limit?: number;
}

export interface FeedbackRequestParams {
  dateFrom?: string;
  dateTo?: string;
  feedbackRequestType?: EFeedbackRequestType;
  orderType?: string;
  driverId?: number | null;
  offset?: number;
  limit?: number;
}

export interface StatsRequestParams {
  dateFrom?: string;
  dateTo?: string;
}

export interface TrendsRequestParams {
  dateFrom?: string;
  dateTo?: string;
}

export interface Feedback {
  orderNumber: string;
  clientName: string;
  driverName: string;
  orderTypeId: number;
  orderTypeName: string;
  id: number;
  accountId: number;
  orderId: number;
  clientId: number;
  driverId: number;
  vehicleId: number;
  rating: number;
  comments: string;
  createdAt: string;
}
