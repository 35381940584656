export const locale = {
    lang: 'en',
    data: {
        'NAV': {
            'APPLICATIONS': 'Applications',
            'ROUTES': 'Routes',
            'SHIFTS': 'Shifts',


            'VEHICLES'        : {
                'TITLE': 'Vehicles',
                'BADGE': '25'
            },
            'CUSTOMERS': {
                'TITLE': 'Customers'
            }
        }
    }
};
