<div class="double-date-picker">
  <mat-form-field
    appearance="outline"
    class="fake"
    floatLabel="always"
    style="line-height:inherit"
  >
    <mat-label>Date Range</mat-label>
    <input matInput readonly>
  </mat-form-field>

  <fuse-date-range
    [fromDate]="filterService.getFilter().dateFrom"
    [toDate]="filterService.getFilter().dateTo"
    [disabled]="apiService.loading"
    (rangeChange)="changeValue($event)"
  ></fuse-date-range>
</div>
