import { Component, OnInit } from '@angular/core';
import { AnalyticsService } from '../../../../services/analytics.service';

@Component({
  selector: 'fuse-feedback-survey-list',
  templateUrl: './feedback-survey-list.component.html',
  styleUrls: ['./feedback-survey-list.component.scss']
})
export class FeedbackSurveyListComponent implements OnInit {
  constructor(
    public analyticsService: AnalyticsService,
  ) { }

  ngOnInit(): void {}
}
