import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Moment } from 'moment';
import { EDatePresets } from 'app/consts/enums';
import { DATE_PRESET_NAMES } from 'app/consts/constants';
import { DateRange } from 'app/models/interfaces';
import { DateTimeService } from 'app/services/date-time.service';

@Component({
  selector: 'fuse-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss']
})
export class DateRangeComponent {
  @Input() fromDate: Moment = null;
  @Input() toDate: Moment = null;
  @Input() disabled = false;
  @Output() fromDateChange = new EventEmitter<Moment>();
  @Output() toDateChange = new EventEmitter<Moment>();
  @Output() rangeChange = new EventEmitter<DateRange>();

  public datePresetList: Array<EDatePresets> = Object.keys(EDatePresets).map(
    key => EDatePresets[key]
  );
  public readonly datePresetNames = DATE_PRESET_NAMES;

  constructor(
    private dateTimeService: DateTimeService,
  ) { }

  public setDatePreset = (preset: EDatePresets): void => {
    const { fromDate, toDate }: DateRange = this.dateTimeService.getDateRange(preset);
    this.fromDateChange.emit(fromDate);
    this.toDateChange.emit(toDate);
    this.rangeChange.emit({ fromDate, toDate });
  }

  public fromDateHandle = (fromDate: Moment) => {
    this.fromDateChange.emit(fromDate);
    this.rangeChange.emit({ fromDate, toDate: this.toDate });
  }

  public toDateHandle = (toDate: Moment) => {
    this.toDateChange.emit(toDate);
    this.rangeChange.emit({ fromDate: this.fromDate, toDate });
  }
}
