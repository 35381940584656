import { Component, OnInit } from '@angular/core';
import { ImportInfoService } from '../import-info.service';
import { IMPORT_HISTORY_STATUSES_MAP } from 'app/consts/constants';
import { map } from 'rxjs/operators';
import { IImportHistory } from 'app/models/interfaces';
import { BehaviorSubject, combineLatest } from 'rxjs';

@Component({
  selector: 'fuse-import-info',
  templateUrl: './import-info.component.html',
  styleUrls: ['./import-info.component.scss']
})
export class ImportInfoComponent implements OnInit {
  public readonly statusesInfo = IMPORT_HISTORY_STATUSES_MAP;

  get isVisible(): boolean {
    return this.importInfoService.isVisible;
  }
  get collapsed(): boolean {
    return this.importInfoService.collapsed;
  }
  set collapsed(value: boolean) {
    this.importInfoService.collapsed = value;
  }
  get isLoading(): boolean {
    return this.importInfoService.isLoading;
  }

  readonly openedTypeId$ = new BehaviorSubject<number | null>(null);

  readonly importList$ = combineLatest(
    [this.openedTypeId$, this.importInfoService.importList$]
  )
    .pipe(
      map(([openedTypeId, list]) => {
        if (!list?.length) return [];
        const types = new Set<number>();
        const result = new Array<IImportHistory & { latest?: boolean }>();
        for (let i of list) {
          if (!types.has(i.typeId)) {
            result.push({ ...i, latest: true });
            types.add(i.typeId);
          } else if (openedTypeId === i.typeId) {
            result.push(i)
          }
        }
        return result;
      })
    );

  constructor(
    public importInfoService: ImportInfoService,
  ) { }

  ngOnInit(): void {
    this.importInfoService.fetchImportHistoryByTimer();
  }

  recap(item: IImportHistory) {
    if(item?.importedEntities && item?.totalEntities) {
      return item.importedEntities + "/" + item.totalEntities;
    }
    return null;
  }

  toggleType(typeId: number) {
    if (this.openedTypeId$.value === typeId) {
      this.openedTypeId$.next(null);
    }
    else {
      this.openedTypeId$.next(typeId);
    }
  }

  public onToggle = (): void => {
    this.collapsed = !this.collapsed;
  }

  public onClose = (): void => {
    this.importInfoService.isVisible = false;
  }

  public isEmptyList = (): boolean => {
    return this.importInfoService.getImportsList().length === 0;
  }

  public onOpenImportDetailDialog = (event: MouseEvent, importId: number): void => {
    event.stopPropagation();
    this.importInfoService.openImportDetailDialog(importId).subscribe();
  }
}
