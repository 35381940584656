<a class="nav-link" matRipple>
    <mat-icon class="nav-link-icon" *ngIf="item.icon">{{item.icon}}</mat-icon>
    <span class="nav-link-title" [translate]="item.translate">{{item.title}}</span>
    <span class="nav-link-badge" *ngIf="item.badge" [translate]="item.badge.translate"
          [ngStyle]="{'background-color': item.badge.bg,'color': item.badge.fg}">
        {{item.badge.title}}
    </span>
    <mat-icon class="collapse-arrow">keyboard_arrow_right</mat-icon>
</a>

<div class="children" [ngClass]="{'open': isOpen}">

    <div class="{{fuseSettings.colorClasses.navbar}}">

        <ng-container *ngFor="let item of item.children">
            <fuse-nav-horizontal-item *ngIf="item.type=='item' || item.type=='button'" [item]="item"></fuse-nav-horizontal-item>
            <fuse-nav-horizontal-collapse *ngIf="item.type=='collapse'" [item]="item"></fuse-nav-horizontal-collapse>
            <fuse-nav-horizontal-collapse *ngIf="item.type=='group'" [item]="item"></fuse-nav-horizontal-collapse>
        </ng-container>

    </div>

</div>