<div class="base-layout">
  <h2 class="header">
    Feedback
    <div class="button-container">
      <fuse-export-feedback></fuse-export-feedback>
    </div>
  </h2>
  <fuse-feedback-customer-all-filter></fuse-feedback-customer-all-filter>
  <div class="form-block">
    <mat-card>
      <mat-card-content>
        <fuse-feedback-survey-list></fuse-feedback-survey-list>
        <fuse-feedback-paginator></fuse-feedback-paginator>
      </mat-card-content>
    </mat-card>
  </div>
</div>
