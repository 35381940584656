<div class="form-block top-spaced-block">
  <div class="grid-2c small-gap">
    <fuse-feedback-bar-chart></fuse-feedback-bar-chart>
    <fuse-feedback-line-chart></fuse-feedback-line-chart>
  </div>

  <div class="form-block top-spaced-block">
    <mat-card>
      <mat-card-title>
        <div class="title-text">Feedback highlights</div>
      </mat-card-title>

      <mat-card-content>
        <div style="padding-top: 20px">
          <fuse-feedback-customer-filter></fuse-feedback-customer-filter>
          <fuse-feedback-survey-list></fuse-feedback-survey-list>
          <button
            mat-flat-button
            color="accent"
            style="margin-top: 20px"
            (click)="onAllFeedback()"
          >See all feedback</button>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
