<mat-form-field appearance="outline">
  <mat-label>Driver</mat-label>
  <mat-select
    placeholder="Driver"
    [value]="(analyticFilterService.filter$ | async).driverId"
    [disabled]="api.loading"
    (selectionChange)="onChangeFilterValue({ driverId: $event.value })"
  >
    <mat-option
      *ngFor="let driver of driversList"
      [value]="driver.id"
    >
      {{ driver.FullName }}
    </mat-option>
  </mat-select>

  <button
    *ngIf="(analyticFilterService.filter$ | async).driverId"
    mat-icon-button
    matSuffix
    [disabled]="api.loading"
    (click)="clearDriverOption($event)"
  >
    <mat-icon>clear</mat-icon>
  </button>
</mat-form-field>
