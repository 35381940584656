import { Injectable } from '@angular/core';
import { IStepOption, TourService } from 'ngx-ui-tour-md-menu';
import { Subject } from 'rxjs';

import { SharedService } from 'app/services/shared.service';
import { ECookies, EWelcomeTourSteps } from 'app/consts/enums';

@Injectable({ providedIn: 'root' })
export class WelcomeTourService {
  public events$ = this.tourService.events$;
  public skipTour$ = new Subject();
  public readonly lastStep = String(EWelcomeTourSteps.Completion);

  constructor(
    private readonly tourService: TourService,
    private readonly sharedService: SharedService,
  ) {
    this.tourService.stepShow$.subscribe(step => {
      if (this.getState()) {
        this.setState(Number(step.stepId));
      }
    });
  }

  public checkStateAndStart = (stepSets: Array<Array<IStepOption>>): void => {
    const currentState = this.getState();
    const currentStateString = String(currentState);

    const acceptableStepSet = stepSets.find(stepSet => {
      const stepsNumberIDs = stepSet.map(step => Number(step.stepId));
      const stepBounds = {
        min: Math.min(...stepsNumberIDs) - 1,
        max: Math.max(...stepsNumberIDs),
      };

      return (
        currentState >= stepBounds.min
        && currentState <= stepBounds.max
      );
    });

    if (acceptableStepSet) {
      this.tourService.initialize(acceptableStepSet, {
        enableBackdrop: true,
        prevBtnTitle: 'Back',
        nextBtnTitle: 'Next',
        endBtnTitle: 'Ok, got it',
      });

      if (acceptableStepSet.map(step => step.stepId).includes(currentStateString)) {
        this.tourService.startAt(currentStateString);
      } else {
        this.tourService.start();
      }
    }
  }

  public hasPrev(step: IStepOption): boolean {
    return this.tourService.hasPrev(step);
  }

  public prev(): void {
    this.tourService.prev();
  }

  public hasNext(step: IStepOption): boolean {
    return this.tourService.hasNext(step);
  }

  public next(): void {
    this.tourService.next();
  }

  public end(): void {
    if (this.lastStep === this.tourService.currentStep.stepId) {
      this.setState(EWelcomeTourSteps.Inactive);
    }
    this.tourService.end();
  }

  public skipTour(): void {
    this.setState(EWelcomeTourSteps.Inactive);
    this.tourService.end();
  }

  public setState(state: EWelcomeTourSteps): void {
    this.sharedService.cookieHelper.set(ECookies.WelcomeTourState, String(state));
  }

  public getState(): EWelcomeTourSteps {
    return Number(this.sharedService.cookieHelper.get(ECookies.WelcomeTourState));
  }
}
