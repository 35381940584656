import { Component, OnDestroy, OnInit } from '@angular/core';
import { AnalyticsFilterService } from '../../shared/services/analytics-filter.service';
import { AnalyticsService } from '../../shared/services/analytics.service';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'fuse-all-feedback',
  templateUrl: './all-feedback.component.html',
  styleUrls: ['./all-feedback.component.scss'],
  providers: [AnalyticsFilterService, AnalyticsService]
})
export class AllFeedbackComponent implements OnInit, OnDestroy {
  private filterSubscription: Subscription;

  constructor(
    private analyticsService: AnalyticsService,
    private filterService: AnalyticsFilterService,
  ) { }

  ngOnInit(): void {
    this.filterSubscription = this.filterService.filter$
      .pipe(debounceTime(300))
      .subscribe(this.analyticsService.fetchFeedback);
  }

  ngOnDestroy() {
    this.filterSubscription.unsubscribe();
  }
}
