import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { groupBy } from 'lodash';
import { EImportStatus, EStorageProperties } from 'app/consts/enums';
import { IImportHistory, IImportHistoryDetails } from 'app/models/interfaces';
import { ApiService } from 'app/services/api.service';
import { StorageService } from 'app/services/storage.service';
import { IImportDetailDialogInput, ImportDetailDialogComponent } from './import-detail-dialog/import-detail-dialog.component';
import { DateTimeService } from '../../../app/services/date-time.service';

interface ITimerOptions {
  immediately?: boolean;
  timer?: number;
}

@Injectable({
  providedIn: 'root',
})
export class ImportInfoService implements OnDestroy {

  public isLoading = false;

  public get isVisible(): boolean {
    return this.getVisibleState();
  }
  public set isVisible(value: boolean) {
    this.setVisibleState(value);
  }
  public get collapsed(): boolean {
    return this.getCollapseState();
  }
  public set collapsed(value: boolean) {
    this.setCollapseState(value);
  }

  private readonly importListSubject = new BehaviorSubject(new Array<IImportHistory>());
  public readonly importList$ = this.importListSubject.asObservable();
  private timer: ReturnType<typeof setInterval>;
  private UPDATE_TIME = 10_000;

  readonly importFinalStatus$ = this.importListSubject.pipe(
    map(list => {

      const statuses = distinctBy(list, 'typeId')
        .reduce((r, item) => r.add(item.statusId), new Set<EImportStatus>());

      if (statuses.has(EImportStatus.Process)) {
        return EImportStatus.Process;
      }

      if (statuses.has(EImportStatus.Fail)) {
        return EImportStatus.Fail;
      }

      return EImportStatus.Finished;
    })
  );

  private readonly subs = [
    this.importFinalStatus$.subscribe(s => {
      if (s !== EImportStatus.Process) {
        this.stopTimer();
      }
    })
  ];

  constructor(
    private api: ApiService,
    private storage: StorageService,
    private dialog: MatDialog,
    private dateTime: DateTimeService,
  ) { }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

  public show = () => {
    this.isVisible = true;
  }

  public hide = () => {
    this.isVisible = false;
  }

  public open = () => {
    this.collapsed = false;
  }

  public close = () => {
    this.collapsed = true;
  }

  public fetchImportHistory = (cb = (r: IImportHistory<null>[]): void => { }) => {
    this.isLoading = true;
    const result = this.api.getImportHistory({ limitByType: 10 });
    result.subscribe({
      next: (res) => {
        this.importListSubject.next(this.onlyTodayImports(res?.data ?? []));
      },
      error: () => { },
      complete: () => {
        this.isLoading = false;
        cb(this.importListSubject.value);
      },
    });
    return result;
  }

  public fetchImportHistoryByTimer = ({ immediately, timer }: ITimerOptions = {}) => {
    if (!this.timer) {
      if (immediately) {
        this.fetchImportHistory(() => {
          this.timer = setInterval(this.fetchImportHistory, timer || this.UPDATE_TIME);
        });
      } else {
        this.timer = setInterval(this.fetchImportHistory, timer || this.UPDATE_TIME);
      }
    }
  }

  private stopTimer() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = undefined;
    }
  }

  public getImportDetail = (importId: number): Observable<IImportHistory<IImportHistoryDetails>> => {
    return this.api.getImportHistoryById(importId)
      .pipe(map((res) => res.data));
  }

  public getImportsList = (): IImportHistory<null>[] => {
    return this.importListSubject.getValue();
  }

  public openImportDetailDialog = (importId: number): Observable<null> => {
    return this.dialog.open<
      ImportDetailDialogComponent, IImportDetailDialogInput, null
    >(ImportDetailDialogComponent, {
      width: '95%',
      maxWidth: '1280px',
      height: '95%',
      maxHeight: '98vh',
      autoFocus: false,
      data: { importId },
    }).afterClosed();
  }

  private setVisibleState = (value: boolean) => {
    this.storage.write<boolean>(EStorageProperties.ImportInfoShowing, value, true);
  }

  private getVisibleState = (): boolean => {
    return this.storage.read<boolean>(EStorageProperties.ImportInfoShowing);
  }

  private setCollapseState = (value: boolean) => {
    this.storage.write<boolean>(EStorageProperties.ImportInfoCollapsing, value, true);
  }

  private getCollapseState = (): boolean => {
    return this.storage.read<boolean>(EStorageProperties.ImportInfoCollapsing) ?? false;
  }

  private onlyTodayImports = (imports: IImportHistory[]): IImportHistory[] => {
    return imports.filter(i => this.dateTime.isToday(i.endDate));
  }
}

function distinctBy<T>(source: T[], field: keyof T) {
  return Object.values(groupBy(source, field)).map(v => v[0]);
}
