// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  environment: 'development',
  reportViewerURL: 'https://live.solbox.it/', // s/be live
  sentryDsn: 'https://bb7265c8af284f139e51ce9b3b1d79d2@o381818.ingest.sentry.io/4505429415034881',
  googleAnalyticsId: undefined,
};
