<mat-card (click)="$event.stopPropagation()">
  <mat-card-title>
    <div class="title-text">Customers feedback</div>
  </mat-card-title>

  <mat-card-content style="height: 180px">
    <div class="chart-block">
      <div class="chart-block__wrapper">
        <div class="chart-block__chart-titles">
          <div *ngFor="let item of dataset">
            <mat-icon class="icon-star icon-star_filled">star</mat-icon>
          </div>
        </div>
        <ngx-charts-bar-horizontal
          [results]="dataset"
          [scheme]="colorScheme"
          [animations]="true"
          [yAxis]="true"
          [barPadding]="24">
        </ngx-charts-bar-horizontal>
      </div>
      <div class="chart-values">
        <div
          *ngFor="let item of dataset"
          class="chart-values__item"
        >
          ({{item.value}})
        </div>
      </div>
      <div class="chart-block__raging">
        <div class="average-ranging">
          {{ averageRanging }}
          <div class="average-ranging-description">Average raging</div>
        </div>
      </div>
    </div>
  </mat-card-content>

  <mat-card-actions style="height: 100px">
    <div class="indicators">
      <div class="indicators__item">
        <div class="indicators__value indicators__value_green">{{ positiveFeedback }}</div>
        <div class="indicators_description">Positive feedback</div>
      </div>
      <div class="indicators__item">
        <div class="indicators__vertical-separator"></div>
      </div>
      <div class="indicators__item">
        <div class="indicators__value indicators__value_red">{{ negativeFeedback }}</div>
        <div class="indicators_description">Negative feedback</div>
      </div>
      <div class="indicators__item">
        <div class="indicators__vertical-separator"></div>
      </div>
      <div class="indicators__item">
        <div class="indicators__value indicators__value_violet">{{ feedbackWithComments }}</div>
        <div class="indicators_description">Feedback with comments</div>
      </div>
    </div>
  </mat-card-actions>
</mat-card>
