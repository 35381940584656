<div class="inputs-wrapper">
  <mat-form-field [floatLabel]="'never'" appearance="standard">
    <input
      matInput
      input="date"
      placeholder="From date"
      [matDatepicker]="fromDatePicker"
      [max]="toDate"
      [(ngModel)]="fromDate"
      [disabled]="disabled"
      (dateChange)="fromDateHandle($event.value)"
      readonly
    >
    <mat-datepicker-toggle
      matSuffix
      [for]="fromDatePicker"
    ></mat-datepicker-toggle>
    <mat-datepicker #fromDatePicker></mat-datepicker>
  </mat-form-field>

  <mat-form-field [floatLabel]="'never'" appearance="standard">
    <input
      matInput
      input="date"
      placeholder="To date"
      [matDatepicker]="toDatePicker"
      [min]="fromDate"
      [(ngModel)]="toDate"
      [disabled]="disabled"
      (dateChange)="toDateHandle($event.value)"
      readonly
    >
    <mat-datepicker-toggle
      matSuffix
      [for]="toDatePicker"
    ></mat-datepicker-toggle>
    <mat-datepicker #toDatePicker></mat-datepicker>
  </mat-form-field>

  <button
    mat-icon-button
    class="preset-dropdown"
    [matMenuTriggerFor]="datePresets"
    [disabled]="disabled"
  >
    <mat-icon>arrow_drop_down</mat-icon>
    <mat-menu #datePresets="matMenu" xPosition="before">
      <button
        *ngFor="let datePreset of datePresetList"
        mat-menu-item
        (click)="setDatePreset(datePreset)"
      >{{ datePresetNames[datePreset] }}</button>
    </mat-menu>
  </button>
</div>
