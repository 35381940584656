<div
  *ngIf="isVisible"
  class="import-info"
>
  <div class="import-info__header">
    <div class="header__title">
      <div>Import</div>
      <fuse-import-status-icon
        *ngIf="collapsed"
        [status]="importInfoService.importFinalStatus$ | async"
      ></fuse-import-status-icon>
    </div>
    <div class="header__buttons">
      <button
        mat-icon-button
        [disabled]="isLoading"
        (click)="onToggle()"
      >
        <mat-icon
          *ngIf="collapsed"
          class="arrow-icon"
        >keyboard_arrow_up</mat-icon>
        <mat-icon
          *ngIf="!collapsed"
          class="arrow-icon"
        >keyboard_arrow_down</mat-icon>
      </button>
      <button
        mat-icon-button
        [disabled]="isLoading"
        (click)="onClose()"
      >
        <mat-icon class="close-icon">close</mat-icon>
      </button>
    </div>
  </div>
  <div
    class="import-info__body"
    [class.collapsed]="collapsed"
  >
    <div class="imports-list">
      <ng-container *ngIf="isLoading && isEmptyList()">
        <div class="grey">
          Loading...
        </div>
      </ng-container>
      <ng-container *ngIf="!isEmptyList()">
        <div
          *ngFor="let importItem of importList$ | async"
          class="imports-list__item"
        >
          <div
            class="cursor-pointer"
            (click)="toggleType(importItem.typeId)"
          >
            <span
              class="outline"
              (click)="onOpenImportDetailDialog($event, importItem)"
              [ngClass]="{ 'old-import': !importItem.latest}"
            >{{ importItem.latest ?  importItem.typeName : '' }} {{ importItem.endDate | date : 'shortDate' }}
            </span>
            <span> - {{ importItem.statusName }} {{recap(importItem)}}</span>
          </div>
          <fuse-import-status-icon [status]="importItem.statusId"></fuse-import-status-icon>
        </div>
      </ng-container>
      <ng-container *ngIf="!isLoading && isEmptyList()">
        <div class="grey">
          No records
        </div>
      </ng-container>
    </div>
  </div>
</div>
