<mat-toolbar>

    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutAlign.gt-xs="space-between center" fxFlex>

        <a target="_blank" mat-button class="mat-pink-bg" fxFlex="0 0 auto" fxLayout="row"
           fxLayoutAlign="start center">
            <mat-icon class="s-16 mr-sm-4">shopping_cart</mat-icon>
            <span>Purchase subscribtion</span>
        </a>

        <div fxLayout="row" fxLayoutAlign="start center" fxHide fxShow.gt-xs>
            <a mat-button href="#" target="_blank">Documentation</a>
            <span>&bull;</span>
            <a mat-button href="#" target="_blank">Changelog</a>
        </div>

    </div>

</mat-toolbar>
