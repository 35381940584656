<mat-card (click)="$event.stopPropagation()">
  <mat-card-title>
    <div class="title-text">Trends</div>
  </mat-card-title>

  <mat-card-content style="height: 180px">
    <div class="chart-block">
      <div class="chart-block__wrapper">
        <ngx-charts-line-chart
          [scheme]="colorScheme"
          [results]="dataset"
          [curve]="curveFn"
          [showGridLines]="false"
          [legend]="false"
          legendPosition="below"
          [xAxis]="true"
          [yAxis]="true"
        >
        </ngx-charts-line-chart>
      </div>
    </div>
  </mat-card-content>

  <mat-card-actions style="height: 100px">
    <div class="legend-block">
      <div class="legend-block__item">
        <i class="circle circle_green"></i>
        Positive feedback
      </div>
      <div class="legend-block__item">
        <i class="circle circle_red"></i>
        Negative feedback
      </div>
      <div class="legend-block__item">
        <i class="circle circle_violet"></i>
        Deliveries with feedback
      </div>
    </div>
  </mat-card-actions>
</mat-card>
