import { Component, OnInit } from '@angular/core';
import { ApiService } from 'app/services/api.service';
import { AnalyticsFilterService } from '../../../../services/analytics-filter.service';
import { FeedbackCustomerFilter } from '../../../../types/types';

@Component({
  selector: 'fuse-only-with-comment-filter',
  templateUrl: './only-with-comment-filter.component.html',
  styleUrls: ['./only-with-comment-filter.component.scss']
})
export class OnlyWithCommentFilterComponent implements OnInit {

  constructor(
    public analyticFilterService: AnalyticsFilterService,
    public api: ApiService,
  ) { }

  ngOnInit(): void {}

  public onChangeFilterValue = (filter: FeedbackCustomerFilter): void => {
    this.analyticFilterService.extendFilter(filter);
  }
}
