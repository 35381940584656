import { Component, HostBinding, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { fuseAnimations } from '@fuse/animations/index';
import { FuseConfigService } from '@fuse/services/config.service';
import { WelcomeTourService } from 'app/services/welcome-tour.service';

@Component({
  selector: 'fuse-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
  animations: fuseAnimations,
})
export class FuseContentComponent implements OnDestroy {
  onConfigChanged: Subscription;
  fuseSettings: any;

  // Disable due to animation glitches using new version of Safari after update the macOS to Sonoma
  // @HostBinding('@routerTransitionUp') routeAnimationUp = false;
  // @HostBinding('@routerTransitionDown') routeAnimationDown = false;
  // @HostBinding('@routerTransitionRight') routeAnimationRight = false;
  // @HostBinding('@routerTransitionLeft') routeAnimationLeft = false;
  @HostBinding('@routerTransitionFade') routeAnimationFade = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private fuseConfig: FuseConfigService,
    public tourService: WelcomeTourService,
  ) {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
    )
    // ---> Disable due to animation glitches using new version of Safari after update the macOS to Sonoma
    // .subscribe((event) => {      
      // switch (this.fuseSettings.routerAnimation) {
      //   case 'fadeIn':
      //     this.routeAnimationFade = !this.routeAnimationFade;
      //     break;
      //   case 'slideUp':
      //     this.routeAnimationUp = !this.routeAnimationUp;
      //     break;
      //   case 'slideDown':
      //     this.routeAnimationDown = !this.routeAnimationDown;
      //     break;
      //   case 'slideRight':
      //     this.routeAnimationRight = !this.routeAnimationRight;
      //     break;
      //   case 'slideLeft':
      //     this.routeAnimationLeft = !this.routeAnimationLeft;
      //     break;
      // }
    // });

    this.onConfigChanged = this.fuseConfig.onConfigChanged.subscribe(
      (newSettings) => this.fuseSettings = newSettings
    );
  }

  ngOnDestroy() {
    this.onConfigChanged.unsubscribe();
  }
}
