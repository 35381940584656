import { Component, OnInit } from '@angular/core';
import { AnalyticsFilterService } from '../../../../services/analytics-filter.service';
import { DateRange } from 'app/models/interfaces';
import { ApiService } from 'app/services/api.service';

@Component({
  selector: 'fuse-date-range-filter',
  templateUrl: './date-range-filter.component.html',
  styleUrls: ['./date-range-filter.component.scss']
})
export class DateRangeFilterComponent implements OnInit {
  constructor(
    public filterService: AnalyticsFilterService,
    readonly apiService: ApiService,
  ) { }

  ngOnInit(): void {
  }

  public changeValue = ({ fromDate, toDate }: DateRange) => {
    this.filterService.extendFilter({ dateFrom: fromDate, dateTo: toDate });
  }
}
