import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeedbackBarChartComponent } from './components/feedback-bar-chart/feedback-bar-chart.component';
import { MatCardModule } from '@angular/material/card';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [
    FeedbackBarChartComponent
  ],
  exports: [
    FeedbackBarChartComponent
  ],
  imports: [
    CommonModule,
    MatCardModule,
    NgxChartsModule,
    MatIconModule,
  ]
})
export class FeedbackBarChartModule { }
