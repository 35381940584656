import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { BehaviorSubject, Subscription } from "rxjs";
import { filter, first, map } from "rxjs/operators";
import { IAccountConfigFull } from "app/models/interfaces";

@Injectable({ providedIn: 'root' })
export class AccountConfigService {
    private inited: Subscription;
    private readonly _config$ = new BehaviorSubject<IAccountConfigFull | null>(null);
    private readonly notEmptyConfig = this._config$.pipe(filter(c => Boolean(c)));

    constructor(private api: ApiService) { }

    get config$() {
        if (!this.inited) {
            this.init();
        }

        return this.notEmptyConfig;
    }

    subscribe(observer: (c: IAccountConfigFull) => void) {
        return this.config$.pipe(first()).subscribe(observer);
    }

    invalidate() {
        this.inited = null;
    }

    private init() {
        this.inited = this.api.getConfigFull()
            .pipe(map(r => r.body))
            .subscribe(c => this._config$.next(c));
    }
}