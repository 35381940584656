import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeedbackPaginatorComponent } from './components/feedback-paginator/feedback-paginator.component';
import { MatPaginatorModule } from '@angular/material/paginator';

@NgModule({
  declarations: [
    FeedbackPaginatorComponent
  ],
  imports: [
    CommonModule,
    MatPaginatorModule
  ],
  exports: [
    FeedbackPaginatorComponent
  ]
})
export class FeedbackPaginatorModule { }
