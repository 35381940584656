import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeedbackLineChartComponent } from './components/feedback-line-chart/feedback-line-chart.component';
import { MatCardModule } from '@angular/material/card';
import { NgxChartsModule } from '@swimlane/ngx-charts';

@NgModule({
  declarations: [
    FeedbackLineChartComponent
  ],
  exports: [
    FeedbackLineChartComponent
  ],
  imports: [
    CommonModule,
    MatCardModule,
    NgxChartsModule,
  ]
})
export class FeedbackLineChartModule { }
