import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { SharedService } from 'app/services/shared.service';
import { SendMessageToVehicleDialogComponent } from 'app/main/content/_shared/send-message-to-vehicle-dialog/send-message-to-vehicle-dialog.component';
import { ECookies, ELiveMapVersion, EModuleIDs, EModuleURLs, EStorageProperties, ETimers } from 'app/consts/enums';
import { Router, RoutesRecognized } from '@angular/router';
import { NoticeService } from '../../services/notice.service';
import { StorageService } from '../../services/storage.service';


@Component({
  selector: 'fuse-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})

export class FuseToolbarComponent implements OnInit {
  public horizontalNav: boolean;
  public noNav: boolean;
  public loggedInUser: string;
  public isExtendedNavigationElement: boolean;
  public isNewLiveMap: boolean;

  constructor(
    private fuseConfig: FuseConfigService,
    private sidebarService: FuseSidebarService,
    public sharedService: SharedService,
    private dialog: MatDialog,
    private router: Router,
    private notice: NoticeService,
    private storageService: StorageService,
  ) {
    this.loggedInUser = this.sharedService.cookieHelper.get(ECookies.UserName);

    this.fuseConfig.onConfigChanged.subscribe((settings) => {
      this.horizontalNav = settings.layout.navigation === 'top';
      this.noNav = settings.layout.navigation === 'none';
    });

    this.isNewLiveMap = this.storageService.read<ELiveMapVersion>(EStorageProperties.LiveMapVersion) === ELiveMapVersion.New;
  }

  ngOnInit(): void {
    this.isExtendedNavigationElement = this.router.url.includes(EModuleURLs.Dashboard);
    this.router.events.subscribe((event: unknown) => {
      if (event instanceof RoutesRecognized) {
        this.isExtendedNavigationElement = event.url.includes(EModuleURLs.Dashboard);
      }
    });
  }

  public toggleSidebarOpened = (key: string): void => {
    this.sidebarService.getSidebar(key).toggleOpen();
  }

  public logOut = (): void => {
    this.sharedService.logOut(true);
  }

  public accountLink = (): string => {
    return this.sharedService.linkToPage(
      EModuleIDs.Accounts, this.sharedService.appConfig?.accountId
    );
  }

  public sendMessageToVehicleClick = (): void => {
    this.dialog.open(SendMessageToVehicleDialogComponent, {
      width: '600px',
      autoFocus: false,
    });
  }

  public onComingSoon = () => {
    this.notice.showSnackBar('Coming Soon... We are working very diligently on this', ETimers.Medium, true);
  }
}
