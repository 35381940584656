<div class="imports-icon">
  <mat-icon
    *ngIf="!isProgress()"
    [svgIcon]="statusesInfo[status].icon"
    [class]="statusesInfo[status].color"
  ></mat-icon>
  <mat-spinner
    *ngIf="isProgress()"
    [diameter]="20"
  ></mat-spinner>
</div>