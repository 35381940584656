import { Component, OnInit } from '@angular/core';
import { AnalyticsFilterService } from '../../../../services/analytics-filter.service';
import { PageEvent } from '@angular/material/paginator';
import { AnalyticsService } from '../../../../services/analytics.service';
import { FeedbackCustomerFilter } from '../../../../types/types';

@Component({
  selector: 'fuse-feedback-paginator',
  templateUrl: './feedback-paginator.component.html',
  styleUrls: ['./feedback-paginator.component.scss']
})
export class FeedbackPaginatorComponent implements OnInit {

  constructor(
    readonly filterService: AnalyticsFilterService,
    readonly dataService: AnalyticsService,
  ) { }

  ngOnInit(): void {
  }

  public pageChange = ({ pageSize, pageIndex }: PageEvent) => {
    const filter: FeedbackCustomerFilter = {
      limit: pageSize,
      offset: pageSize * pageIndex
    };
    this.filterService.extendFilter(filter, false);
  }

  public getPageIndex = ({ offset, limit }: FeedbackCustomerFilter): number => {
    return offset / limit;
  }
}
