import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { SharedService } from 'app/services/shared.service';

@Injectable({providedIn: 'root'})
export class Authguard implements CanActivate {
  constructor(
    private sharedService: SharedService
  ) { }

  canActivate(): boolean {
    if (this.sharedService.isLoggedIn()) {
      return true;
    } else {
      this.sharedService.goToLogin();
      return false;
    }
  }
}
