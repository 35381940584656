import { Injectable } from '@angular/core';
import { ApiService } from 'app/services/api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Feedback, FeedbackCustomerFilter, FeedbackRequestParams, FeedbackStats, FeedbackTrends, LineChartSeries, StatsRequestParams, TrendsRequestParams } from '../types/types';
import { map } from 'rxjs/operators';
import { IListData } from 'app/models/interfaces';
import { DateTimeService } from 'app/services/date-time.service';

@Injectable()
export class AnalyticsService {
  private feedbackBarChartSubject: BehaviorSubject<FeedbackStats>;
  private feedbackLineChartSubject: BehaviorSubject<FeedbackTrends>;
  private feedbackSubject: BehaviorSubject<IListData<Feedback>>;

  public feedbackBarChart$: Observable<FeedbackStats>;
  public feedbackLineChart$: Observable<FeedbackTrends>;
  public feedback$: Observable<IListData<Feedback>>;

  constructor(
    private api: ApiService,
    private dateTime: DateTimeService,
  ) {
    this.feedbackBarChartSubject = new BehaviorSubject(this.getDefaultFeedbackValues());
    this.feedbackLineChartSubject = new BehaviorSubject(this.getDefaultTrendsValues());
    this.feedbackSubject = new BehaviorSubject(this.getDefaultFeedback());
    this.feedbackBarChart$ = this.feedbackBarChartSubject.asObservable();
    this.feedbackLineChart$ = this.feedbackLineChartSubject.asObservable();
    this.feedback$ = this.feedbackSubject.asObservable();
  }

  public fetchCharts = (filter: FeedbackCustomerFilter): void => {
    this.fetchFeedbackBarChart(filter);
    this.fetchFeedbackLineChart(filter);
    this.fetchFeedback(filter);
  }

  public fetchFeedbackBarChart = ({ dateFrom, dateTo }: FeedbackCustomerFilter): void => {
    const params: StatsRequestParams = {
      dateFrom: this.dateTime.fromDateObject(dateFrom),
      dateTo: this.dateTime.fromDateObject(dateTo),
    };
    this.api.getFeedbackStatsChart(params).pipe(map(res => res.data))
      .subscribe((data: FeedbackStats) => {
        this.feedbackBarChartSubject.next(data);
      });
  }

  public fetchFeedbackLineChart = ({ dateFrom, dateTo }: FeedbackCustomerFilter): void => {
    const params: TrendsRequestParams = {
      dateFrom: this.dateTime.fromDateObject(dateFrom),
      dateTo: this.dateTime.fromDateObject(dateTo),
    };
    this.api.getFeedbackTrendsChart(params).pipe(map(res => res.data))
      .subscribe((data) => {
        this.feedbackLineChartSubject.next(data);
      });
  }

  public fetchFeedback = (filter: FeedbackCustomerFilter): void => {
    const params: FeedbackRequestParams = {
      ...filter,
      dateFrom: this.dateTime.fromDateObject(filter.dateFrom),
      dateTo: this.dateTime.fromDateObject(filter.dateTo),
    };
    this.api.getFeedback(params).pipe(map(res => res.data))
      .subscribe((data) => {
        this.feedbackSubject.next(data);
      });
  }

  public getDefaultFeedbackValues = (): FeedbackStats => {
    return {
      chartValues: [
        {
          'name': '1',
          'value': 0
        },
        {
          'name': '2',
          'value': 0
        },
        {
          'name': '3',
          'value': 0
        },
        {
          'name': '4',
          'value': 0
        },
        {
          'name': '5',
          'value': 0
        }
      ],
      indicatorSet: {
        averageRanging: 0,
        positiveFeedback: 0,
        negativeFeedback: 0,
        feedbackWithComments: 0
      }
    };
  }

  public getDefaultTrendsValues = (): FeedbackTrends => {
    return {
      chartSeries: [
        {
          name: '1',
          series: this.getDefaultLineChartSeries(20)
        },
        {
          name: '2',
          series: this.getDefaultLineChartSeries(10)
        },
        {
          name: '3',
          series: this.getDefaultLineChartSeries()
        },
      ]
    };
  }

  private getDefaultLineChartSeries = (val = 0): LineChartSeries[] => {
    return [
      {
        'name': '2020-01-01',
        'value': val
      },
      {
        'name': '2020-01-02',
        'value': val
      },
      {
        'name': '2020-01-03',
        'value': val
      },
      {
        'name': '2020-01-04',
        'value': val
      },
      {
        'name': '2020-01-05',
        'value': val
      },
      {
        'name': '2020-01-06',
        'value': val
      },
      {
        'name': '2020-01-07',
        'value': val
      }
    ];
  }

  public getDefaultFeedback = (): IListData<Feedback> => {
    return {
      meta: {
        count: 10,
        total: 0
      },
      data: []
    };
  }
}
