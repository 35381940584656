import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';

import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { GoogleAnalyticsService } from './services/google-analytics.service';

import { locale as navigationEnglish } from './navigation/i18n/en';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { CUSTOM_ICONS } from 'app/consts/constants';

@Component({
  selector: 'fuse-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    private translate: TranslateService,
    private fuseNavigationService: FuseNavigationService,
    private fuseSplashScreen: FuseSplashScreenService,
    private fuseTranslationLoader: FuseTranslationLoaderService,
    private matIconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private readonly googleAnalyticsService: GoogleAnalyticsService,
  ) {
    // fixes browser's back button
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    // Add languages
    this.translate.addLangs(['en']);

    // Set the default language
    this.translate.setDefaultLang('en');

    // Set the navigation translations
    this.fuseTranslationLoader.loadTranslations(navigationEnglish);

    // Use a language
    this.translate.use('en');

    Object.keys(CUSTOM_ICONS).forEach(icon => this.matIconRegistry.addSvgIcon(
      icon,
      this.sanitizer.bypassSecurityTrustResourceUrl(CUSTOM_ICONS[icon])
    ));
  }

  ngOnInit(): void {
    if (environment.production) {
      this.googleAnalyticsService.initialize();
    }
  }
}
