import { Component, OnInit } from '@angular/core';
import { ECustomIcons } from 'app/consts/enums';
import { ApiService } from 'app/services/api.service';
import { ExportService } from 'app/services/export.service';
import { AnalyticsFilterService } from '../../shared/services/analytics-filter.service';
import { FeedbackCustomerFilter, FeedbackRequestParams } from '../../shared/types/types';
import { DateTimeService } from 'app/services/date-time.service';

@Component({
  selector: 'fuse-export-feedback',
  templateUrl: './export-feedback.component.html',
  styleUrls: ['./export-feedback.component.scss']
})
export class ExportFeedbackComponent implements OnInit {
  public readonly xlsIcon = ECustomIcons.Xls;

  get isApiLoading(): boolean {
    return this.api?.loading || false;
  }

  constructor(
    private api: ApiService,
    private exportService: ExportService,
    private filterService: AnalyticsFilterService,
    private dateTime: DateTimeService,
  ) { }

  ngOnInit(): void { }

  public onExportXLS = (): void => {
    const filter: FeedbackCustomerFilter = this.filterService.getFilter();
    const params: FeedbackRequestParams = {
      ...filter,
      dateFrom: this.dateTime.fromDateObject(filter.dateFrom),
      dateTo: this.dateTime.fromDateObject(filter.dateTo),
    };
    this.exportService.exportFeedback(params);
  }
}
