import { Component, OnInit } from '@angular/core';
import { AnalyticsService } from '../../../../services/analytics.service';
import { curveBumpX } from 'd3-shape';

@Component({
  selector: 'fuse-feedback-line-chart',
  templateUrl: './feedback-line-chart.component.html',
  styleUrls: ['./feedback-line-chart.component.scss']
})
export class FeedbackLineChartComponent implements OnInit {

  public dataset: any[] = [];
  public colorScheme = { domain: ['#4CC260', '#FD3D39', '#4A148C'] };
  // https://github.com/d3/d3-shape#curves
  public curveFn = curveBumpX;

  constructor(
    private analyticsService: AnalyticsService,
  ) { }

  ngOnInit(): void {
    this.analyticsService.feedbackLineChart$.subscribe((chartData) => {
      this.dataset = chartData.chartSeries;
    });
  }

}
