import { Component, ElementRef, Inject, NgZone, OnDestroy, OnInit, SecurityContext, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import {
  IAutoallocateResult,
  IEventMessageData,
  IServerMessageStreamInput,
} from 'app/models/interfaces';
import { ApiService } from 'app/services/api.service';

@Component({
  templateUrl: './server-message-stream-dialog.component.html',
})
export class ServerMessageStreamDialogComponent implements OnInit, OnDestroy {
  @ViewChild('terminal', { static: true }) private terminalWindow: ElementRef<HTMLDivElement>;
  @ViewChild('solutionFile') private solutionFileLink: ElementRef<HTMLAnchorElement>;
  public progressBarValue = 0;
  public messages: Array<{
    text: SafeHtml,
    class?: string,
  }> = [];
  private eventSource: EventSource;

  public allocationResult: IAutoallocateResult;
  public fileLink = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IServerMessageStreamInput,
    private dialogRef: MatDialogRef<ServerMessageStreamDialogComponent, IAutoallocateResult>,
    private ngZone: NgZone,
    private domSanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {

    if (!this.data.eventSourceLink) {
      return;
    }

    this.eventSource = new EventSource(this.data.eventSourceLink);
    this.eventSource.onmessage = (e: MessageEvent) => {
      this.ngZone.run(() => {
        const dataObj: IEventMessageData = JSON.parse(e.data);
        if (dataObj.current_idx && dataObj.tot_qty) {
          this.progressBarValue = dataObj.current_idx / dataObj.tot_qty * 100;
        }

        if (this.data.allocateOrderToManifest === true && dataObj.data) {
          this.allocationResult = JSON.parse(dataObj.data);
        }

        if (dataObj.file) {
          const xlsContent = (
            'data:application/octet-stream;charset=utf-16le;base64,'
            + dataObj.file
          );
          this.fileLink = this.domSanitizer.sanitize(
            SecurityContext.URL,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
              encodeURI(xlsContent)
            ),
          );
          const link = this.solutionFileLink.nativeElement;
          link.setAttribute('href', this.fileLink);
          link.setAttribute('download', 'solution.xlsx');
          link.text = 'solution.xlsx';
          this.solutionFileLink.nativeElement.click();
        }

        this.messages.push({
          text: this.domSanitizer.bypassSecurityTrustHtml(
            dataObj.message
          ),
          class: dataObj.is_error ? 'error' : '',
        });
        this.scrollToTerminalBottom();
      });
    };

    this.eventSource.onerror = () => {
      this.ngZone.run(() => {
        this.eventSource.close();
        this.progressBarValue = 100;
        this.messages.push({ text: ' ' });
        this.messages.push({ text: 'Done...' });
        this.scrollToTerminalBottom();
      });
    };

  }

  ngOnDestroy(): void {
    if (this.eventSource) {
      this.eventSource.close();
    }
  }

  private scrollToTerminalBottom = (): void => {
    this.terminalWindow.nativeElement.scrollIntoView(false);
  }

  public allocate = (): void => {
    this.dialogRef.close(this.allocationResult);
  }
}
