import { Component, OnDestroy, OnInit } from '@angular/core';
import { AnalyticsService } from '../../../../services/analytics.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'fuse-feedback-bar-chart',
  templateUrl: './feedback-bar-chart.component.html',
  styleUrls: ['./feedback-bar-chart.component.scss']
})
export class FeedbackBarChartComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  public positiveFeedback: string;
  public negativeFeedback: string;
  public feedbackWithComments: string;
  public averageRanging: string;

  public dataset: any[] = [];
  public colorScheme = { domain: ['#FE9526'] };

  constructor(
    public analyticsService: AnalyticsService,
  ) { }

  ngOnInit(): void {
    this.initEvents();
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  private initEvents = (): void => {
    this.subscription = this.analyticsService.feedbackBarChart$.subscribe((chartData) => {
      if ('indicatorSet' in chartData) {
        this.positiveFeedback = (chartData.indicatorSet.positiveFeedback.toFixed?.(0) || 0) + '%';
        this.negativeFeedback = (chartData.indicatorSet.negativeFeedback.toFixed?.(0) || 0) + '%';
        this.feedbackWithComments = (chartData.indicatorSet.feedbackWithComments.toFixed?.(0) || 0) + '%';
        this.averageRanging = (chartData.indicatorSet.averageRanging.toFixed?.(1) || 0) + ' / 5.0';
      }
      if ('chartValues' in chartData) {
        this.dataset = chartData.chartValues;
      }
    });
  }
}
