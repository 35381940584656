import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { EModuleIDs } from 'app/consts/enums';
import { SharedService } from 'app/services/shared.service';
import { AnalyticsFilterService } from '../../shared/services/analytics-filter.service';
import { AnalyticsService } from '../../shared/services/analytics.service';

@Component({
  selector: 'fuse-customer-satisfaction-tab',
  templateUrl: './customer-satisfaction-tab.component.html',
  styleUrls: ['./customer-satisfaction-tab.component.scss'],
})
export class CustomerSatisfactionTabComponent implements OnInit, OnDestroy {
  private filterSubscription: Subscription;

  constructor(
    private analyticsService: AnalyticsService,
    private filterService: AnalyticsFilterService,
    private sharedService: SharedService,
  ) { }

  ngOnInit(): void {
    this.filterSubscription = this.filterService.filter$
      .pipe(debounceTime(300))
      .subscribe(this.analyticsService.fetchCharts);
  }

  ngOnDestroy(): void {
    this.filterSubscription.unsubscribe();
  }

  public onAllFeedback = (): void => {
    this.sharedService.goToPage(EModuleIDs.AllFeedback);
  }
}
