<div class="base-layout">
  <h2 class="header">
    Analytics
    <div class="button-container">
      <fuse-date-range-filter></fuse-date-range-filter>
    </div>
  </h2>

  <mat-tab-group (selectedTabChange)="tabChanged($event)" [(selectedIndex)]="currentTabIndex">
    <mat-tab [label]="tabs.customerSatisfaction">
      <fuse-customer-satisfaction-tab
        *ngIf="currentTabIndex === 0"
      ></fuse-customer-satisfaction-tab>
    </mat-tab>
    <mat-tab [label]="tabs.drivers" disabled>
      <ng-template mat-tab-label>
        <span (click)="onComingSoon()">{{ tabs.drivers }}</span>
      </ng-template>
    </mat-tab>
    <mat-tab [label]="tabs.performance" disabled>
      <ng-template mat-tab-label>
        <span (click)="onComingSoon()">{{ tabs.performance }}</span>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
