<router-outlet>
  <tour-step-template>
    <ng-template let-step="step">
      <mat-card (click)="$event.stopPropagation()">
        <mat-card-title>
          <div class="title-text">{{step?.title}}</div>
        </mat-card-title>
  
        <mat-card-content [innerHTML]="step?.content"></mat-card-content>
  
        <mat-card-actions>
          <button
            *ngIf="tourService.hasPrev(step)"
            mat-button
            class="prev"
            (click)="tourService.prev()"
          >{{step?.prevBtnTitle}}</button>
          <button
            *ngIf="tourService.hasNext(step)"
            mat-button
            class="next"
            (click)="tourService.next()"
          >{{step?.nextBtnTitle}}</button>
          <button
            *ngIf="!tourService.hasNext(step)"
            mat-button
            (click)="tourService.end()"
          >{{step?.endBtnTitle}}</button>
  
          <div
            *ngIf="tourService.lastStep !== step.stepId"
            class="tour-end"
            (click)="tourService.skipTour()"
          >Skip helper</div>
        </mat-card-actions>
      </mat-card>
    </ng-template>
  </tour-step-template>
</router-outlet>

