import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Moment } from 'moment';

import { IMPORT_DIALOG_TITLES } from 'app/consts/constants';
import { EOrderImportOptions } from 'app/consts/enums';

export interface IImportOrdersDialogInput {
  type: EOrderImportOptions;
  myobCompaniesString?: string;
  orderMentumProperties?: Array<string>;
  withoutDateRange?: boolean;
}

export interface IImportOrdersDialogOutput {
  fromDate?: string;
  toDate?: string;
  multiStage: boolean;
  createManifests: boolean;
  myobURI?: string;
  filterProperty?: string;
}

interface IMYOBcompany {
  Uri: string;
  Name: string;
}

@Component({
  templateUrl: './import-orders-dialog.component.html',
})
export class ImportOrdersDialogComponent implements OnInit {
  public fromDate: Moment;
  public toDate: Moment;
  public multiStage = false;
  public createManifests = false;
  public filterProperty = '';

  public title: string;
  public multiStageAvailable = false;
  public createManifestsAvailable = false;

  public importOptions = EOrderImportOptions;

  public myobCompany: string;
  public myobCompanies: Array<IMYOBcompany> = [];

  public orderMentumPropertyList: Array<string> = [];

  public get isDateRange(): boolean {
    return !Boolean(this.data?.withoutDateRange);
  }

  constructor(
    private dialogRef: MatDialogRef<ImportOrdersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IImportOrdersDialogInput,
  ) { }

  ngOnInit(): void {
    this.title = this.defineTitle(this.data.type);

    this.multiStageAvailable = this.data.type === EOrderImportOptions.MYOB;

    this.createManifestsAvailable = [
      EOrderImportOptions.JIWA,
      EOrderImportOptions.MYOB,
    ].includes(this.data.type);

    if (this.data.type === EOrderImportOptions.MYOB && this.data.myobCompaniesString) {
      this.myobCompanies = JSON.parse(this.data.myobCompaniesString);
    }

    if (this.data.type === EOrderImportOptions.OrderMentum && this.data.orderMentumProperties) {
      this.orderMentumPropertyList = this.data.orderMentumProperties;
    }
  }

  private defineTitle = (type: EOrderImportOptions): string => {
    return IMPORT_DIALOG_TITLES[type];
  }

  public okClick = (): void => {
    const output: IImportOrdersDialogOutput = {
      ...(this.isDateRange ? { fromDate: this.fromDate.format('YYYY-MM-DD') } : {}),
      ...(this.isDateRange ? { toDate: this.toDate.format('YYYY-MM-DD') } : {}),
      multiStage: this.multiStage,
      createManifests: this.createManifests,
      myobURI: this.myobCompany || undefined,
      filterProperty: this.filterProperty
    };
    this.dialogRef.close(output);
  }
}
