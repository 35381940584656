import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { AnalyticsFilterService } from '../../shared/services/analytics-filter.service';
import { AnalyticsService } from '../../shared/services/analytics.service';
import { NoticeService } from '../../../../services/notice.service';

@Component({
  selector: 'fuse-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss'],
  providers: [AnalyticsFilterService, AnalyticsService]
})
export class AnalyticsComponent implements OnInit {
  public currentTabIndex = 0;
  public readonly tabs = {
    customerSatisfaction: 'Customer Satisfaction',
    drivers: 'Drivers',
    performance: 'Performance',
  };

  constructor(
    private notice: NoticeService,
  ) { }

  ngOnInit(): void {
  }

  public tabChanged = (event: MatTabChangeEvent) => {
  }

  public onComingSoon = (): void => {
    this.notice.showSnackBar('Coming soon');
  }
}
