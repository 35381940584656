<mat-form-field appearance="outline">
  <mat-label>Order type</mat-label>
  <mat-select
    placeholder="Order type"
    [value]="(analyticFilterService.filter$ | async).orderType"
    [disabled]="api.loading"
    (selectionChange)="onChangeFilterValue({ orderType: $event.value })"
  >
    <mat-option [value]="'Delivery'">Delivery</mat-option>
    <mat-option [value]="'Pickup'">Pickup</mat-option>
    <mat-option [value]="'PickupDelivery'">Pickup - Delivery</mat-option>
    <mat-option [value]="'Service'">Service</mat-option>
  </mat-select>
</mat-form-field>
