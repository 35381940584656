import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface IConfirmationInput {
  title: string;
  text: string;
  confirmBtnText?: string;
  confirmBtnClass?: string;
  cancelBtnText?: string;
  cancelBtnClass?: string;
  reverseBtn?: boolean;
}

@Component({
  templateUrl: './confirm-dialog.component.html',
})
export class ConfirmDialogComponent {
  public get confirmBtnText(): string {
    return this.data.confirmBtnText ?? 'Ok';
  }
  public get confirmBtnClass(): string {
    return this.data.confirmBtnClass ?? 'mat-accent-200-bg';
  }

  public get cancelBtnText(): string {
    return this.data.cancelBtnText ?? 'Cancel';
  }
  public get cancelBtnClass(): string {
    return this.data.cancelBtnClass ?? 'mat-grey-200-bg';
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IConfirmationInput
  ) { }
}
