import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeedbackSurveyListComponent } from './components/feedback-survey-list/feedback-survey-list.component';
import { FeedbackSurveyItemComponent } from './components/feedback-survey-item/feedback-survey-item.component';
import { MatCardModule } from '@angular/material/card';
import { RouterLinkWithHref } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { RatingStarsModule } from 'app/shared/modules/rating-stars/rating-stars.module';
import { NotFoundModule } from 'app/shared/modules/not-found/not-found.module';

@NgModule({
  declarations: [
    FeedbackSurveyListComponent,
    FeedbackSurveyItemComponent
  ],
  exports: [
    FeedbackSurveyListComponent
  ],
  imports: [
    CommonModule,
    MatCardModule,
    RouterLinkWithHref,
    MatIconModule,
    RatingStarsModule,
    NotFoundModule
  ]
})
export class FeedbackSurveyListModule { }
