<div class="dialog">
  <h1 mat-dialog-title class="dialog-title">
    {{ title }}
    <button mat-icon-button aria-label="close dialog" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </h1>

  <div mat-dialog-content class="dialog-content">
    <div class="server-message-container">
      <div class="terminal-wrapper">
        <div>
          <div
            class="message"
            *ngFor="let message of messages"
            [innerHTML]="message.text"
            [ngClass]="message.class"
          ></div>
        </div>
      </div>
    </div>
  </div>

  <div mat-dialog-actions class="button-container">
    <button
      mat-button
      class="mat-grey-200-bg"
      mat-dialog-close
    >Close</button>
  </div>
</div>
