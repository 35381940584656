import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AnalyticsComponent } from './pages/analytics/analytics.component';
import { FuseMainComponent } from 'app/main/main.component';
import { Authguard } from 'app/authguard';
import { MatTabsModule } from '@angular/material/tabs';
import { CustomerSatisfactionTabComponent } from './components/customer-satisfaction-tab/customer-satisfaction-tab.component';
import { MatCardModule } from '@angular/material/card';
import { FeedbackBarChartModule } from './shared/modules/feedback-bar-chart/feedback-bar-chart.module';
import { FeedbackLineChartModule } from './shared/modules/feedback-line-chart/feedback-line-chart.module';
import { FeedbackCustomerFilterModule } from './shared/modules/feedback-customer-filter/feedback-customer-filter.module';
import { FeedbackSurveyListModule } from './shared/modules/feedback-survey-list/feedback-survey-list.module';
import { MatButtonModule } from '@angular/material/button';
import { AllFeedbackComponent } from './pages/all-feedback/all-feedback.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { DateRangeFilterModule } from './shared/modules/date-range-filter/date-range-filter.module';
import { FeedbackPaginatorModule } from './shared/modules/feedback-paginator/feedback-paginator.module';
import { MatIconModule } from '@angular/material/icon';
import { ExportFeedbackComponent } from './components/export-feedback/export-feedback.component';

const routes: Routes = [
  {
    path: 'app',
    component: FuseMainComponent,
    canActivate: [Authguard],
    children: [
      { path: 'analytics', component: AnalyticsComponent },
      { path: 'all-feedback', component: AllFeedbackComponent },
    ],
  },
];

@NgModule({
  declarations: [
    AnalyticsComponent,
    CustomerSatisfactionTabComponent,
    AllFeedbackComponent,
    ExportFeedbackComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatTabsModule,
    MatCardModule,
    FeedbackBarChartModule,
    FeedbackLineChartModule,
    FeedbackCustomerFilterModule,
    FeedbackSurveyListModule,
    MatButtonModule,
    MatPaginatorModule,
    DateRangeFilterModule,
    FeedbackPaginatorModule,
    MatIconModule,
  ],
})
export class AnalyticsModule { }
