/// <reference types="@types/googlemaps" />
import * as Sentry from '@sentry/angular-ivy';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Location, TitleCasePipe } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';

import * as L from 'leaflet';
import 'leaflet-routing-machine';
import 'mapbox-gl-leaflet';
import * as mapboxgl from 'mapbox-gl';

import { ApiService } from 'app/services/api.service';
import {
  IAppConfig,
  IAutoallocateResult,
  IAutoallocateRoute,
  IManifestNew,
  IManifestStatusInfo,
  IModule,
  IModuleRoute,
  IOrder,
  IOrderAny,
  IOrderNode,
  IPageSettings, IPagingParams,
  IRouteQueryParams,
  IServerMessageStreamInput, ISortParams,
  ISortSettings, ITableSettings,
  IVehicle,
} from 'app/models/interfaces';
import {
  EColours,
  ECookies,
  ECustomIcons,
  EDriverImportOptions,
  EManifestStatuses,
  EMapTypes,
  EModuleExtIDs,
  EModuleIDs,
  EModuleURLs,
  EOnboardingSteps,
  EOrderImportOptions,
  EOrderStatuses,
  EOrderTypes,
  ERoles,
  ESafetyInspectionStatus,
  EStorageProperties,
  ETextClasses,
  ETimers,
  ETimes,
  EVehicleImportOptions,
} from 'app/consts/enums';
import {
  CONTACT_PAGE,
  DASHBOARD_DOT_MARKER,
  DEFAULT_APP_CONFIG,
  DEFAULT_COOKIE_PARAMS,
  DEFAULT_ICON,
  DEFAULT_MAP_ZOOM,
  IMPORT_BUTTONS_NAMES,
  IMPORT_DRIVER_BUTTONS_NAMES,
  IMPORT_VEHICLE_BUTTONS_NAMES,
  INSPECTIONS_STATUSES_MAP,
  LOGIN_ROUTE,
  MAIN_ROUTE,
  MANIFEST_STATUSES_MAP,
  MAP_COLOURS,
  MAP_LAYER_ICONS,
  NBSP,
  ONBOARDING_ROUTE,
  ORDER_ICON,
  ORDER_STATUS_COLOUR_MAP,
  ORDER_STATUS_ICON_MAP, ORDER_TYPE_ICON_LIVE_MAP,
  ORDER_TYPE_ICON_MAP,
  ROUTES,
  VEHICLE_ICON,
} from 'app/consts/constants';
import { ServerMessageStreamDialogComponent } from 'app/main/content/_shared/server-message-stream-dialog/server-message-stream-dialog.component';
import {
  IImportOrdersDialogInput,
  IImportOrdersDialogOutput,
  ImportOrdersDialogComponent,
} from 'app/main/content/orders/dialogs/import-orders-dialog/import-orders-dialog.component';
import { ConfirmDialogComponent, IConfirmationInput, } from 'app/main/content/_shared/confirm-dialog/confirm-dialog.component';
import { IOnboardingStatus } from 'app/main/content/onboarding/steps/onboarding-stepper.component';
import { StorageService } from './storage.service';
import { NoticeService } from './notice.service';
import { Observable } from 'rxjs';
import { DateTimeService } from './date-time.service';
import { RegionalPipe } from '@fuse/pipes/regional.pipe';
import { ImportInfoService } from '../../@fuse/components/import-info/import-info.service';
import { IManifestMapActions, IWaypointMapActions } from '../modules/dashboard/types';
import { AccountConfigService } from './account-config.service';

// Fix for Leaflet default icons
L.Marker.prototype.options.icon = DEFAULT_ICON;

@Injectable({ providedIn: 'root' })
export class SharedService {
  public isReady = false;

  public navigationRaw: Array<IModule> = [];
  public navigation: Array<IModule> = [];

  public ordersForAllocation: Array<IOrder> = [];

  public appConfig: IAppConfig;

  public accountName: string;
  public role: ERoles;

  public dashboardFilterSettingsString: string;

  public titleCase: TitleCasePipe = new TitleCasePipe();

  public accountTempStorage: {
    accountID: number;
    accountName: string;
  };
  public integrationLogos: { [key: number]: string } = {
    1: 'assets/logos/unleashed_logo.png',
    2: 'assets/logos/myob_logo.png',
    3: 'assets/logos/myob_logo.png',
    4: 'assets/logos/myob_logo.png',
    5: 'assets/logos/neto_logo.png',
    6: 'assets/logos/navision_logo.png',
    7: 'assets/logos/dear_logo.png',
    8: 'assets/logos/business_central_logo.png',
    9: 'assets/logos/xero_logo.png',
  };

  public defaultNeoIntegrationLogo: string = '/assets/logos/default_logo.png';

  public getIntegrationLogoUrl = (integrationId: number): string => { return this.integrationLogos[integrationId] || null };

  public readonly textHelper = {
    getTypeText: (typeID: EOrderTypes): string => {
      return this.appConfig.orderTypesMap[typeID];
    },
    getStatusText: (statusID: EOrderStatuses): string => {
      return this.appConfig.orderStatusesMap[statusID];
    },
    getStatusColorClass: (statusID: EOrderStatuses): ETextClasses | '' => {
      return ORDER_STATUS_COLOUR_MAP[statusID] || '';
    },
    getStatusIcon: (statusID: EOrderStatuses): ECustomIcons => {
      return ORDER_STATUS_ICON_MAP[statusID];
    },
    getAttributeText: (attributeID: number): string => {
      return this.appConfig.accountAttributes.find(
        attribute => attribute.id === attributeID
      )?.Name || '-';
    },
    getLoadTypeName: (loadTypeID: number): string => {
      return this.appConfig.loadTypes.find(
        type => type.id === loadTypeID
      )?.name || '-';
    },
    getLoadOptionName: (loadOptionID: number): string => {
      return this.appConfig.loadOptions.find(
        option => option.id === loadOptionID
      )?.name || '-';
    },
    currency: (amount: number): string => {
      return `${this.regionalPipe.transform('currency')}${(Number(amount) || 0).toFixed(2)}`;
    },
    getDimensionText: (dimension: number): string => {
      return `${(Number(dimension) || 0).toFixed(2)}`;
    },
    getCapacityText: (capacity: number): string => {
      return `${Math.round(Number(capacity) || 0)}`;
    },
    getOrderNodeType: (order: IOrderAny, pickup?: boolean): string => {
      if (order.TypeId === EOrderTypes.PickupDelivery) {
        const _pickup = this.isOrderPickup(order, pickup);
        return this.textHelper.getTypeText((_pickup
          ? EOrderTypes.Pickup
          : EOrderTypes.Delivery
        ));
      } else {
        return this.textHelper.getTypeText(order.TypeId);
      }
    },
    displayOrderRefOrID: (order: Partial<IOrderAny>, withoutHash = false): string => {
      if ('cust_ref' in order && order.cust_ref) {
        return order.cust_ref;
      } if ('CustomerReference' in order && order.CustomerReference) {
        return order.CustomerReference;
      } else {
        return `${withoutHash ? '' : '#'}${order.id}`;
      }
    },
    getOrderIconByType: (
      order: IOrderAny,
      forNode = false,
      explicitPickup?: boolean,
    ): ECustomIcons => {
      if (forNode && order.TypeId === EOrderTypes.PickupDelivery) {
        if (this.isOrderPickup(order, explicitPickup)) {
          return ECustomIcons.PickupDeliveryPickupNode;
        } else {
          return ECustomIcons.PickupDeliveryDeliveryNode;
        }
      }
      return ORDER_TYPE_ICON_MAP[order.TypeId] || ECustomIcons.Delivery;
    },
    displayAddress: (order: IOrderAny, pickup?: boolean): string => {
      const address = (this.isOrderPickup(order, pickup)
        ? order.pickup_address
        : order.dest_address
      );

      const lastCommaIndex = (address || '').lastIndexOf(',');

      return (lastCommaIndex !== -1
        // cuts country from address
        ? address.slice(0, lastCommaIndex)
        : address
      );
    },
    displayPlannedTime: (order: IOrderAny, pickup?: boolean): string => {
      const planned = [];
      const _pickup = this.isOrderPickup(order, pickup);
      const startTime = (_pickup
        ? 'pdt' in order && order.pdt || 'PickupTime' in order && order.PickupTime
        : 'ddt' in order && order.ddt || 'DeliveryTime' in order && order.DeliveryTime
      );
      const tolerance = (_pickup
        ? ((
          'ptol' in order && order.ptol
          || 'PickupTolerance' in order && order.PickupTolerance
        ) ?? this.appConfig.defaultPickupTolerance)
        : ((
          'dtol' in order && order.dtol
          || 'DeliveryTolerance' in order && order.DeliveryTolerance
        ) ?? this.appConfig.defaultDeliveryTolerance)
      );

      if (startTime) {
        planned.push(startTime);
        planned.push(new Date(
          new Date(startTime).getTime() + tolerance * ETimes.Minute
        ).toISOString());
      }

      return planned.map(this.dateTime.UTCTime).join(`${NBSP}-${NBSP}`) || '-';
    },
    getOrderSummaryMaxDimension: (order: IOrderAny): string => {
      return (
        (this.appConfig.showOrderItemDimensions && 'Length' in order && order.Length)
          ? this.textHelper.getDimensionText(order.Length > 0 ? order.Length : 0) + ' m'
          : '-'
      );
    },
    getOrderMaxDimension: (order: IOrderAny): string => {
      if (this.appConfig.showOrderItemDimensions && ('order_max_dimension' in order && order.order_max_dimension > 0)) {
        return this.textHelper.getDimensionText(order.order_max_dimension) + ' m';
      } else if ('Length' in order) {
        return this.textHelper.getDimensionText((order.Length > 0 ? order.Length : 0)) + ' m';
      } else {
        return '-';
      }
    },
    getCapacityLabel: (order: IOrderAny): string => {
      const capacityLabel = (
        (this.appConfig.showOrderCapacity && 'capacity_name' in order)
          ? this.titleCase.transform(order.capacity_name)
          : '-'
      );

      return capacityLabel;
    },
    getOrderSummaryCapacity: (order: IOrderAny): string => {
      return (
        (this.appConfig.showOrderCapacity && 'volume' in order && order.volume && 'capacity_units' in order)
          ? this.textHelper.getCapacityText(order.volume) + ' ' + order.capacity_units
          : '-'
      );
    },
    getOrderTotalCapacity: (order: IOrderAny): string => {
      if (this.appConfig.showOrderCapacity && 'capacity_units' in order) {
        const suffix = ' ' + order.capacity_units;

        if ('order_total_capacity' in order && order.order_total_capacity > 0) {
          return this.textHelper.getCapacityText(order.order_total_capacity) + suffix;
        }
        if ('volume' in order) {
          return this.textHelper.getCapacityText(order.volume > 0 ? order.volume : 0) + suffix;
        }
      }

      return '-';
    },
    getOrderDashboardTitle: (order: IOrderAny): string => {
      const deliveryAmount = (
        (this.appConfig.showOrderDeliveryCost && 'delivery_price' in order)
          ? this.textHelper.currency(order.delivery_price)
          : ''
      );

      return [
        this.textHelper.displayOrderRefOrID(order),
        this.textHelper.getTypeText(order.TypeId),
        deliveryAmount,
      ].filter(str => str).join(', ');
    },
    getOrderCustomerName: (order: IOrderAny, pickup?: boolean): string => {
      const _pickup = this.isOrderPickup(order, pickup);

      if (_pickup && 'pickup_client_name' in order && order.pickup_client_name) {
        return order.pickup_client_name;
      } else if (!_pickup && 'delivery_client_name' in order && order.delivery_client_name) {
        return order.delivery_client_name;
      } else if (order.TypeId === EOrderTypes.PickupDelivery && order.client_name) {
        // may fail if customer name contains ' to ' string 
        const customers = order.client_name.replace('From ', '').split(' to ');
        return (_pickup ? customers[0] : customers.slice(1).join(' to '));
      } else {
        return order.client_name || '-';
      }
    },
    getOrderActualTime: (order: IOrderAny, pickup?: boolean): string => {
      const actualTime = (this.isOrderPickup(order, pickup)
        ? 'apdt' in order && order.apdt || 'PickedUpTime' in order && order.PickedUpTime
        : 'addt' in order && order.addt || 'DeliveredTime' in order && order.DeliveredTime
      );

      if (actualTime) {
        return this.dateTime.UTCTime(actualTime);
      } else {
        return '';
      }
    },
    getOrderAttributeList: (orderAttributeIDs: Array<number>): string => {
      if (orderAttributeIDs?.length > 0) {
        const uniqueIds = Array.from(new Set(orderAttributeIDs));
        return uniqueIds.map(attributeID => this.textHelper.getAttributeText(attributeID)).join(', ');
      }
      return '';
    },
    generateOrderTooltip: (order: IOrderAny, pickup?: boolean): string => {
      const _pickup = this.isOrderPickup(order, pickup);

      const defineAddress = (): string => {
        return [
          this.textHelper.getOrderNodeType(order, _pickup),
          this.textHelper.displayAddress(order, _pickup) || '-',
        ].join(': ');
      };

      const defineActualTime = (): string => {
        const actualTime = this.textHelper.getOrderActualTime(order, _pickup);

        if (actualTime) {
          return `<div>Actual time: ${actualTime}</div>`;
        } else {
          return '';
        }
      };

      return [
        '<div class="dashboard-tooltip">',
        `<div class="label-row">${this.textHelper.getOrderDashboardTitle(order)}</div>`,
        `<div class="details">`,
        `<div>Customer: ${this.textHelper.getOrderCustomerName(order, _pickup)}</div>`,
        `<div>${defineAddress()}</div>`,
        defineActualTime(),
        `<div>Planned Time: ${this.textHelper.displayPlannedTime(order)}</div>`,
        (this.appConfig.showOrderCapacity
          ? `<div>${this.textHelper.getCapacityLabel(order)}: ${this.textHelper.getOrderTotalCapacity(order)}</div>`
          : ''
        ),
        (this.appConfig.showOrderItemDimensions
          ? `<div>Max Dimension: ${this.textHelper.getOrderMaxDimension(order)}</div>`
          : ''
        ),
        `<div>Attributes: ${this.textHelper.getOrderAttributeList(order.orderAttributes)}</div>`,
        '</div>',
        '</div>',
      ].join('');
    },
    getManifestStatusInfo: (manifestStatus: EManifestStatuses): IManifestStatusInfo => {
      return this.appConfig.manifestStatusesMap[manifestStatus];
    },
    getLastOnlineCircleColor: (vehicle: IVehicle, compareTime: number): ETextClasses => {
      if (vehicle?.LastOnline) {
        const timeLength = compareTime - new Date(vehicle.LastOnline).getTime();
        if (timeLength < 5 * ETimes.Minute) {
          return ETextClasses.Green;
        } else if (timeLength < 9 * ETimes.Hour) {
          return ETextClasses.Orange;
        }
      }
      return ETextClasses.Grey;
    },
    displayOrderNodeTime: (orderNode: IOrderNode): string => {
      const timeObj = {
        prefix: '',
        time: '',
      };

      if (orderNode.IsPickup === 0 && orderNode.DeliveredTime) {
        timeObj.time = orderNode.DeliveredTime;
      } else if (orderNode.IsPickup === 1 && orderNode.PickedUpTime) {
        timeObj.time = orderNode.PickedUpTime;
      } else if (orderNode.earliest_arrival) {
        timeObj.prefix = '~';
        timeObj.time = orderNode.earliest_arrival;
      }

      if (timeObj.time) {
        return `${timeObj.prefix}${this.dateTime.UTCTime(timeObj.time)}`;
      } else {
        return '-';
      }
    },
    getCapacityHeader: (capacityName: string, capacityUnit: string): string => {
      if (capacityName) {
        const formattedString = capacityName.split(' ').map((name) => {
          return name.slice(0, 1).toUpperCase() + name.slice(1).toLowerCase();
        }).join(' ');
        return (formattedString + ' ' + capacityUnit);
      } else {
        return 'Capacity';
      }
    },
    orderNodeHaveTimeAlert: (orderNode: IOrderNode): boolean => {
      const finished = ([
        EOrderStatuses.Delivered,
        EOrderStatuses.Cancelled,
      ].includes(orderNode.StatusId)
        || (
          orderNode.StatusId === EOrderStatuses.PickedUp
          && orderNode.TypeId === EOrderTypes.Pickup
        )
      );

      return orderNode.OnTime === 1 && !finished;
    },
    safetyStatusIcon: (status: ESafetyInspectionStatus): string => {
      return INSPECTIONS_STATUSES_MAP[status]?.icon || 'close';
    },
    safetyStatusColor: (status: ESafetyInspectionStatus): EColours => {
      return INSPECTIONS_STATUSES_MAP[status]?.color || EColours.Grey;
    },
  };

  public readonly navigationHelper = {
    history: history,
    goBack: () => this.location.back(),
  };

  public readonly checkRole: Record<ERoles, () => boolean> = {
    [ERoles.User]: () => this.role === ERoles.User,
    [ERoles.Customer]: () => this.role === ERoles.Customer,
  };

  public readonly cookieHelper = {
    check: (cookie: ECookies): boolean => this.cookieService.check(cookie),
    set: (cookie: ECookies, value: string): void => {
      this.cookieService.set(cookie, value, ...DEFAULT_COOKIE_PARAMS);
    },
    get: (cookie: ECookies): string => this.cookieService.get(cookie),
  };

  public readonly ordersImportHelper = {
    defineImportButtons: (): Array<EOrderImportOptions> => {
      const importButtons: Array<EOrderImportOptions> = [];

      if (this.appConfig.hasJiwaLinked) {
        importButtons.push(EOrderImportOptions.JIWA);
      }
      if (this.appConfig.hasMyObLinked) {
        importButtons.push(EOrderImportOptions.MYOB);
      }
      if (this.appConfig.hasShopifyLinked) {
        importButtons.push(EOrderImportOptions.Shopify);
      }
      // TO DO HARDCODED must be changed [SB-182]
      if ([1107].includes(this.appConfig.accountId)) {
        importButtons.push(EOrderImportOptions.Woodwards);
      }
      // TO DO HARDCODED must be changed [SB-182]
      if ([1097].includes(this.appConfig.accountId)) {
        importButtons.push(EOrderImportOptions.Accredo);
      }
      // TO DO HARDCODED must be changed [SB-182]
      if ([51].includes(this.appConfig.accountId)) {
        importButtons.push(EOrderImportOptions.Dynamics);
      }
      if (this.appConfig.hasWooCommerceLinked) {
        importButtons.push(EOrderImportOptions.WooCommerce);
      }
      if (this.appConfig.hasOrderMentumLinked) {
        importButtons.push(EOrderImportOptions.OrderMentum);
      }
      if (this.appConfig.hasFreshoLinked) {
        importButtons.push(EOrderImportOptions.Fresho);
      }
      return importButtons;
    },
    getImportButtonName: (button: EOrderImportOptions): string => {
      return IMPORT_BUTTONS_NAMES[button] || '-';
    },
    import: (option: EOrderImportOptions, afterImportAction: () => void): void => {
      const processImport = async (dialogResult?: IImportOrdersDialogOutput): Promise<any> => {
        if (dialogResult || option === EOrderImportOptions.WooCommerce) {
          const processingTitlesMap: Record<EOrderImportOptions, string> = {
            [EOrderImportOptions.JIWA]: 'Importing orders from JIWA...',
            [EOrderImportOptions.MYOB]: 'Importing orders from MYOB...',
            [EOrderImportOptions.Shopify]: 'Importing orders from Shopify...',
            [EOrderImportOptions.Woodwards]: 'Importing orders from Woodwards database...',
            [EOrderImportOptions.Accredo]: 'Importing orders from Accredo...',
            [EOrderImportOptions.WooCommerce]: 'Importing orders from WooCommerce...',
            [EOrderImportOptions.OrderMentum]: 'Importing orders from OrderMentum...',
            [EOrderImportOptions.Dynamics]: 'Importing orders from Dynamics...',
            [EOrderImportOptions.Fresho]: 'Importing orders from Fresho...',
          };

          const processingSourcesMap: Record<EOrderImportOptions, (importData: IImportOrdersDialogOutput) => Observable<number>> = {
            [EOrderImportOptions.JIWA]: this.api.startJiwaOrderImport,
            [EOrderImportOptions.MYOB]: this.api.startMyobOrdersImport,
            [EOrderImportOptions.Shopify]: this.api.startShopifyOrdersImport,
            [EOrderImportOptions.Woodwards]: this.api.startWoodwardsOrdersImport,
            [EOrderImportOptions.Accredo]: this.api.startAccredoOrdersImport,
            [EOrderImportOptions.WooCommerce]: this.api.startWooCommerceOrdersImport,
            [EOrderImportOptions.OrderMentum]: this.api.startMentumOrdersImport,
            [EOrderImportOptions.Dynamics]: this.api.startDynamicsOrdersImport,
            [EOrderImportOptions.Fresho]: this.api.startFreshoOrdersImport,
          };

          const processingTitle = processingTitlesMap[option];
          if (processingTitle) {
            const importId$ = processingSourcesMap[option](dialogResult);

            importId$.subscribe(importId => this.showServerMessageStreamDialog(
              {
                title: processingTitle,
                safeCloseable: true,
                eventSourceLink: this.api.getImportStreamLink(importId),
              },
              () => { this.fetchImportInformation(); afterImportAction() },
              false,
            ));
          }
        }
      };

      const openDialog = (
        myobCompaniesString?: string,
        orderMentumProperties?: any,
        withoutDateRange?: boolean,
      ): void => {
        this.dialog.open<
          ImportOrdersDialogComponent,
          IImportOrdersDialogInput,
          IImportOrdersDialogOutput
        >(ImportOrdersDialogComponent, {
          width: '600px',
          autoFocus: false,
          data: {
            type: option,
            myobCompaniesString: myobCompaniesString,
            orderMentumProperties: orderMentumProperties,
            withoutDateRange: withoutDateRange,
          },
        }).afterClosed().subscribe(processImport);
      };

      if (option === EOrderImportOptions.MYOB) {
        this.api.getMyobCompanies().subscribe(res => {
          openDialog(res.body, null);
        });
      } else if (option === EOrderImportOptions.OrderMentum) {
        this.api.getOrderMentumProperties().subscribe(res => {
          openDialog(null, res?.body?.data?.propertyList);
        });
      } else if (option === EOrderImportOptions.WooCommerce) {
        processImport();
      } else if (option === EOrderImportOptions.Fresho) {
        openDialog(null, null, true);
      } else {
        openDialog();
      }
    },
    handleFileOrdersImport: (event: Event, afterImportAction: () => void): void => {
      const eventTarget = event.target as HTMLInputElement;
      const file: File = eventTarget.files[0];

      if (file) {
        if (file.name.search(/^.*\.(xls|xlsx|csv)$/i) !== -1) {
          const importId$ = file.name.split('.').pop().toLowerCase().includes('xls')
            ? this.api.startXlsOrdersImport(file)
            : this.api.startCsvOrdersImport(file);

          importId$.subscribe(importId => this.showServerMessageStreamDialog(
            {
              title: 'Processing the file...',
              safeCloseable: true,
              eventSourceLink: this.api.getImportStreamLink(importId),
            },
            afterImportAction,
            false));
          
        } else {
          console.error(`Wrong extension for file: "${file.name}", expected ".xls(x) or .csv"`);
          this.notice.showSnackBar(
            'Not supported extension', ETimers.Medium, true
          );
        }
      }
      eventTarget.value = null;
    }
  };


  public readonly driverImportHelper = {
    defineImportButtons: (): Array<EDriverImportOptions> => {
      const importButtons: Array<EDriverImportOptions> = [];

      if (this.appConfig.hasMixLinked) {
        importButtons.push(EDriverImportOptions.MiX);
      }
      return importButtons;
    },
    getImportButtonName: (button: EDriverImportOptions): string => {
      return IMPORT_DRIVER_BUTTONS_NAMES[button] || '-';
    },
    import: (option: EDriverImportOptions, afterImportAction: () => void): void => {
      const processImport = (): void => {
        if (option === EDriverImportOptions.MiX) {
          const processingTitlesMap: Record<EDriverImportOptions, string> = {
            [EDriverImportOptions.MiX]: 'Importing drivers from MiX Telematics...',
          };

          const processingSourcesMap: Record<EDriverImportOptions, () => Observable<number>> = {
            [EDriverImportOptions.MiX]: this.api.startMixDriverImport,
          };

          const processingTitle = processingTitlesMap[option];
          if (processingTitle) {
            const importId$ = processingSourcesMap[option]();
            importId$.subscribe(importId => this.showServerMessageStreamDialog(
              {
                title: processingTitle,
                safeCloseable: true,
                eventSourceLink: this.api.getImportStreamLink(importId),
              },
              afterImportAction,
              false
            ));
          }
        }
      };

      if (option === EDriverImportOptions.MiX) {
        processImport();
      }
    }
  };

  public readonly vehicleImportHelper = {
    defineImportButtons: (): Array<EVehicleImportOptions> => {
      const importButtons: Array<EVehicleImportOptions> = [];

      if (this.appConfig.hasMixLinked) {
        importButtons.push(EVehicleImportOptions.MiX);
      }
      return importButtons;
    },
    getImportButtonName: (button: EVehicleImportOptions): string => {
      return IMPORT_VEHICLE_BUTTONS_NAMES[button] || '-';
    },
    import: (option: EVehicleImportOptions, afterImportAction: () => void): void => {
      const processImport = (): void => {
        if (option === EVehicleImportOptions.MiX) {
          const processingTitlesMap: Record<EVehicleImportOptions, string> = {
            [EVehicleImportOptions.MiX]: 'Importing vehicles from MiX Telematics...',
          };

          const processingSourcesMap: Record<EVehicleImportOptions, () => Observable<number>> = {
            [EVehicleImportOptions.MiX]: this.api.startMixVehiclesImport,
          };

          const processingTitle = processingTitlesMap[option];
          if (processingTitle) {
            const importId$ = processingSourcesMap[option]();
            importId$.subscribe(importId => this.showServerMessageStreamDialog(
              {
                title: processingTitle,
                safeCloseable: true,
                eventSourceLink: this.api.getImportStreamLink(importId),
              },
              afterImportAction,
              false
            ));
          }
        }
      };

      if (option === EVehicleImportOptions.MiX) {
        processImport();
      }
    },

    handleFileVehiclesImport: (event: Event, afterImportAction: () => void): void => {
      const eventTarget = event.target as HTMLInputElement;
      const file: File = eventTarget.files[0];

      if (file) {
        if (file.name.search(/^.*\.(xls|xlsx)$/i) !== -1) {
          const importId$ = this.api.startXlsVehiclesImport(file);
          importId$.subscribe(importId => this.showServerMessageStreamDialog(
            {
              title: 'Processing the file...',
              safeCloseable: true,
              eventSourceLink: this.api.getImportStreamLink(importId),
            },
            afterImportAction,
            false
          ));
        } else {
          console.error(`Wrong extension for file: "${file.name}", expected ".xls(x) or .csv"`);
          this.notice.showSnackBar(
            'Not supported extension', ETimers.Medium, true
          );
        }
      }
      eventTarget.value = null;
    }
  };

  public readonly safetyItemImportHelper = {
    handleSafetyItemImport: (event: Event, afterImportAction: () => void): void => {
      const eventTarget = event.target as HTMLInputElement;
      const file: File = eventTarget.files[0];

      if (file) {
        if (file.name.search(/^.*\.(xls|xlsx)$/i) !== -1) {
          const importId$ = this.api.startXlsSafetyItemsImport(file);
          importId$.subscribe(importId => this.showServerMessageStreamDialog(
            {
              title: 'Processing the file...',
              safeCloseable: true,
              eventSourceLink: this.api.getImportStreamLink(importId),
            },
            afterImportAction,
            false));
          
        } else {
          console.error(`Wrong extension for file: "${file.name}", expected ".xls(x) or .csv"`);
          this.notice.showSnackBar(
            'Not supported extension', ETimers.Medium, true
          );
        }
      }
      eventTarget.value = null;
    }
  };

  public readonly onboardingHelper = {
    onboardingStatus: undefined as IOnboardingStatus,
    onboardingState: undefined as Record<EOnboardingSteps, boolean>,
    finishStep: (step: EOnboardingSteps): void => {
      this.onboardingHelper.onboardingState[step] = true;
      this.onboardingHelper.afterFinishAction(step);
    },
    afterFinishAction: (step: EOnboardingSteps): void => { },
  };

  constructor(
    private regionalPipe: RegionalPipe,
    private injector: Injector,
    private router: Router,
    private cookieService: CookieService,
    private notice: NoticeService,
    private api: ApiService,
    private location: Location,
    private dialog: MatDialog,
    private storageService: StorageService,
    private dateTime: DateTimeService,
    private importInfoService: ImportInfoService,
  ) {
    this.role = this.cookieHelper.get(ECookies.Role) as ERoles;
    if (this.isLoggedIn()) {
      this.getModules();
    }

    // fixes routing bugs when url changes but router do not follow
    this.location.subscribe((ev) => {
      if (ev.url !== this.router.url) {
        this.router.navigateByUrl(ev.url);
      }
    });
  }

  public isLoggedIn = (): boolean => {
    return (
      this.cookieHelper.check(ECookies.UserName)
      && this.cookieHelper.check(ECookies.AccountName)
    );
  }

  public getModules = (): void => {
    this.api.getModules().subscribe(this.setModules, this.logOut);
  }


  private setModules = (modules: Array<IModule>): void => {
    this.navigationRaw = modules.map(module => {
      /*      // TO DO need to rename at backend side [SB-139]
            if (module.id === EModuleIDs.Customers) {
              module.title = 'Customers';
            }
      
            // TO DO need to rename at backend side
            if (module.id === EModuleIDs.Manifests) {
              module.title = 'Manifests';
            }
      
            // TO DO need to rename at backend side
            if (module.id === EModuleIDs.OrdersAllocation) {
              module.title = 'Order Allocation';
            }
      */
      // TO DO may be should be removed totally and build navbar routes from frontend enum [SB-123] or [SB-139]
      module.url = ROUTES[module.id] || module.url;

      return module;
    });

    const routes = Object.keys(ROUTES);
    this.navigation = this.navigationRaw.filter((navs) =>
      navs.visible
      && routes.includes(navs.id)
    );

    /*
        const accountModuleOrder = this.navigation.findIndex(
          module => module.id === EModuleIDs.Accounts
        );
    
        if (accountModuleOrder !== -1) {
          this.navigation = [
            ...this.navigation.slice(0, accountModuleOrder + 1),
            {
              idx: 111,
              id: EModuleIDs.Users,
              url: EModuleURLs.Users,
              icon: 'people',
              title: 'Users',
              type: 'button',
              visible: true,
              readonly: this.navigation[accountModuleOrder].readonly,
            },
            ...this.navigation.slice(accountModuleOrder + 1)
          ];
        }
    */
        if (this.role !== ERoles.Customer && this.isModuleAvailable(EModuleIDs.Orders)) {
          this.navigation.push({
            idx: 100,
            id: EModuleIDs.Reports,
            url: EModuleURLs.Reports,
            icon: 'list_alt',
            title: 'Reports',
            type: 'button',
            visible: true,
            readonly: false,
          });
        }
/*         if (this.role !== ERoles.Customer && this.isModuleAvailable(EModuleIDs.Orders)) {
          this.navigation.push({
            idx: 100,
            id: EModuleIDs.ReportsUnderconstruction,
            url: EModuleURLs.ReportsUnderconstruction,
            icon: 'list_alt',
            title: 'Reports (New)',
            type: 'button',
            visible: true,
            readonly: false,
          });
        }
 */        
    if (this.isModuleAvailable(EModuleIDs.Orders)) {
      this.navigation.unshift({
        idx: 666,
        icon: 'dashboard',
        id: EModuleIDs.Dashboard,
        readonly: false,
        title: 'Live Map',
        type: 'button',
        url: EModuleURLs.Dashboard,
        visible: true,
      });
    }

    this.navigation.unshift({
      icon: ECustomIcons.PieChart,
      title: 'Management Dashboard',
      type: 'collapse',
      children: [
        {
          idx: 50,
          icon: 'list_alt',
          id: EModuleIDs.Analytics,
          readonly: false,
          title: 'Analytics',
          type: 'button',
          url: EModuleURLs.Analytics,
          visible: true,
        },
        {
          idx: 51,
          icon: 'list_alt',
          id: EModuleIDs.AllFeedback,
          readonly: false,
          title: 'All Feedback',
          type: 'button',
          url: EModuleURLs.AllFeedback,
          visible: true,
        },
      ]
    } as IModule);

    const repairerModuleAvailable = this.isExtModuleAvailable(EModuleExtIDs.SafetyRepair);
    const safetyManagementModuleAvailable = this.isExtModuleAvailable(EModuleExtIDs.Safety);
    const safetyBlock = repairerModuleAvailable || safetyManagementModuleAvailable;

    if (safetyBlock) {
      this.navigation.push(
        {
          icon: 'health_and_safety',
          title: 'Prestart Management',
          type: 'collapse',
          children: [
            {
              idx: 701,
              icon: 'content_paste',
              id: EModuleIDs.SafetyReport,
              readonly: false,
              title: (safetyManagementModuleAvailable ? 'Reports' : 'Allocated Reports'),
              type: 'button',
              url: EModuleURLs.SafetyReport,
              visible: true,
            },
            {
              idx: 702,
              icon: 'article',
              id: EModuleIDs.SafetyPlans,
              readonly: false,
              title: 'Plans',
              type: 'button',
              url: EModuleURLs.SafetyPlans,
              visible: safetyManagementModuleAvailable,
            },
            {
              idx: 703,
              icon: 'settings',
              id: EModuleIDs.SafetySettings,
              readonly: false,
              title: 'Prestart Settings',
              type: 'button',
              url: EModuleURLs.SafetySettings,
              visible: safetyManagementModuleAvailable,
            },
          ].filter(module => module.visible),
        } as IModule,
      );
    }

    if (
      this.isModuleOperational(EModuleIDs.Orders)
      || this.isModuleOperational(EModuleIDs.Vehicles)
    ) {
      this.navigation.push({
        idx: 101,
        icon: 'settings',
        id: EModuleIDs.Settings,
        readonly: false,
        title: 'Settings',
        type: 'button',
        url: EModuleURLs.Settings,
        visible: true,
      });
    }

    if (this.navigationRaw.length > 0) {
      this.getAppData();
    }
  }

  private getAppData = (): void => {
    this.accountName = this.cookieHelper.get(ECookies.AccountName);
    this.role = this.cookieHelper.get(ECookies.Role) as ERoles;

    this.api.getAppData().subscribe(
      (res) => {
        this.appConfig = {
          ...res,
          // renaming and value securing
          accountAttributes: res.attributes || [],
          loadTypes: res.unitsOfmeasure || [],
          loadOptions: res.unitsOfmeasureStatuses || [],
          zones: res.zones || [],

          // making maps from arrays (order types and statuses)
          orderTypesMap: (res.orderTypes || []).reduce((map, type) => {
            map[type.id] = type.name;
            return map;
          }, {} as Record<EOrderTypes, string>),
          orderStatusesMap: (res.orderStatuses || []).reduce((map, status) => {
            map[status.id] = status.name;
            return map;
          }, {} as Record<EOrderStatuses, string>),
          manifestStatusesMap: MANIFEST_STATUSES_MAP,
        };
        this.setUnfilledAppSettingsAsDefaults();

        this.api.setAccountInfo(res.accountId, res.APIKEY);

        Sentry.setTag('userName', this.cookieHelper.get(ECookies.UserName));
        Sentry.setExtra('accountId', res.accountId);
        Sentry.setExtra('accountName', this.accountName);

        this.isReady = true;
      },
      this.logOut
    );
  }

  public setUnfilledAppSettingsAsDefaults = (): void => {
    Object.keys(DEFAULT_APP_CONFIG).forEach((param) => {
      this.appConfig[param] = this.appConfig[param] ?? DEFAULT_APP_CONFIG[param];
    });
  }

  public logOut = (withRequest = false): void => {
    const logout = (): void => {
      this.injector.get(AccountConfigService).invalidate();
      this.cookieService.deleteAll('/', null, true, 'None');
      this.isReady = false;
      this.goToLogin();
    };
    if (withRequest) {
      this.api.logout().subscribe(logout);
    } else {
      logout();
    }
  }

  private isOrderPickup = (order: IOrderAny, pickup?: boolean): boolean => {
    return pickup ?? ('IsPickup' in order && order.IsPickup === 1);
  }

  public transformOrderToOrderNodes = (
    order: IOrder | IOrderNode,
  ): [IOrderNode, IOrderNode] | [IOrderNode] => {
    const orderTypeID = order.TypeId;
    const unmatchedFields: Partial<IOrderNode> = {
      PickupTolerance: ('ptol' in order && order.ptol) ?? ('PickupTolerance' in order && order.PickupTolerance),
      DeliveryTolerance: ('dtol' in order && order.dtol) ?? ('DeliveryTolerance' in order && order.DeliveryTolerance),
    };
    if (orderTypeID === EOrderTypes.PickupDelivery) {
      return [
        { ...order, IsPickup: 1, ...unmatchedFields },
        { ...order, IsPickup: 0, ...unmatchedFields },
      ];
    } else {
      return [{
        ...order,
        IsPickup: (order.TypeId === EOrderTypes.Pickup ? 1 : 0),
        ...unmatchedFields
      }];
    }
  }

  public updateManifestAutoallocate = (
    manifest: IManifestNew,
    route: IAutoallocateRoute,
    shiftStartsAt: number,
    orderNodes: Array<IOrderNode>,
    allocatedOrdersIDs?: Array<number>,
  ): void => {
    manifest.orders = route.nodes.slice(1, -1).filter(x => x.order_id && x.order_id > 0).map(node => {
      const orderNode = {
          ...orderNodes.find(x => x.id === node.order_id
            && ((x.IsPickup === 0 && node.delivery !== null && node.delivery.valueOf() === true)
            || (x.IsPickup === 1 && node.delivery !== null && node.delivery.valueOf() === false))),
        ...{
          travel_time: node.travel_time,
          travel_dist: node.travel_dist,
          time_min: new Date(
            (shiftStartsAt + node.time_min) * ETimes.Second
          ).toISOString(),
          time_max: new Date(
            (shiftStartsAt + node.time_max) * ETimes.Second
          ).toISOString(),
          service_time: node.service_time,
        },
      };
      allocatedOrdersIDs?.push(orderNode.id);
      return orderNode;
    });

    manifest.driverId = route.driver_id;
    manifest.vehicleId = route.vehicle_id;

    manifest.depo_start = route.depo_start;
    manifest.depo_end = route.depo_end;
    manifest.start_name = route.start_name;
    manifest.start_lat = route.start_lat;
    manifest.start_lng = route.start_lng;
    manifest.finish_name = route.finish_name;
    manifest.end_lat = route.end_lat;
    manifest.end_lng = route.end_lng;
    manifest.breaks = route.breaks;

    manifest.shift_starts_at = route.route_start_time;
  }

  public initiateMapWithBottomRightControls = (element: HTMLDivElement): L.Map => {
    const map = L.map(element, {
      zoomControl: false,
      zoom: DEFAULT_MAP_ZOOM,
    });
    L.control.zoom({
      position: 'bottomright',
    }).addTo(map).getContainer().classList.add('grey-styled');
    return map;
  }

  public setMultipleTileLayers = (map: L.Map): Record<EMapTypes, L.TileLayer|L.MapboxGL> => {
    const tileLayers: Record<EMapTypes, L.TileLayer|L.MapboxGL> = {
      [EMapTypes.Default]: L.tileLayer(this.api.getMapTilesURL(EMapTypes.Default), {
        attribution: '<a href="https://www.maptiler.com/copyright/" target="_blank">© MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">© OpenStreetMap contributors</a>',
      }),
      [EMapTypes.Hybrid]: L.tileLayer(this.api.getMapTilesURL(EMapTypes.Hybrid), {
        attribution: '<a href="https://www.openstreetmap.org/copyright" target="_blank">© OpenStreetMap contributors</a>',
      }),
      [EMapTypes.Traffic]: L.mapboxGL( {
        accessToken: 'pk.eyJ1IjoidHBvcGVzY3UiLCJhIjoiY20wdjJwczcxMTRuZzJycHR0ajEzanFtcSJ9.u6Oz0bt4t39hHNc4RnAScw',
        style: 'mapbox://styles/mapbox/traffic-day-v2',
      }),
    };
    const activeLayer = this.cookieHelper.get(ECookies.MapType) as EMapTypes || EMapTypes.Default;
    tileLayers[activeLayer].addTo(map);

    this.cookieHelper.set(ECookies.MapType, activeLayer);

    const layerControl = new L.Control({ position: 'bottomright' });
    layerControl.onAdd = (() => {
      const container = L.DomUtil.create('div', 'layer-selector');
      const createButton = (mapType: EMapTypes): HTMLElement => {
        const active = activeLayer === mapType;
        const button = L.DomUtil.create('div', 'layer-select-button' + (active ? ' active' : ''), container);
        button.style.backgroundImage = `url(${MAP_LAYER_ICONS[mapType]})`;
        button.style.backgroundSize = 'cover';

        L.DomEvent.on(button, 'click', () => {
          const currentLayer = this.cookieHelper.get(ECookies.MapType) as EMapTypes;
          if (mapType !== currentLayer) {
            Object.keys(mapButtons).forEach(mapTypeKey => {
              mapButtons[mapTypeKey].classList.remove('active');
            });
            tileLayers[currentLayer]?.remove();
            this.cookieHelper.set(ECookies.MapType, mapType);
            button.classList.add('active');
            tileLayers[mapType].addTo(map);
          }
        });
        return button;
      };

      const mapButtons: Record<EMapTypes, HTMLElement> = {
        [EMapTypes.Default]: createButton(EMapTypes.Default),
        [EMapTypes.Traffic]: createButton(EMapTypes.Traffic),
        [EMapTypes.Hybrid]: createButton(EMapTypes.Hybrid),
      };
      return container;
    });
    layerControl.addTo(map);
    return tileLayers;
  }

  public addManifestToMap = (
    manifest: IManifestNew,
    map: L.Map,
    colour = MAP_COLOURS[0],
    showVehicle = true,
    dashboard?: {
      toggleManifest: () => void,
      selected: () => number,
      manifestMapActions: IManifestMapActions,
      waypointMapActions: IWaypointMapActions,
    },
  ): L.LatLngBounds => {
    const paneName = `manifest-pane-${manifest.id}-${Date.now()}`;
    const pane = map.createPane(paneName);
    pane.classList.add('manifest-pane');
    if (!dashboard) {
      // It's necessary so that the layer of the manifest
      // doesn't overlay the layer of the real route
      pane.style.zIndex = '600';
    }

    const bounds = L.latLngBounds(null);
    const mapItems: Array<L.Marker> = [];

    if (showVehicle && manifest.linkedVehicle?.lat && manifest.linkedVehicle?.lng) {
      const position = L.latLng(
        manifest.linkedVehicle?.lat,
        manifest.linkedVehicle?.lng,
      );

      mapItems.push(
        L.marker(
          position,
          { icon: VEHICLE_ICON(true), pane: paneName },
        ).addTo(map)
      );
      bounds.extend(position);
    }

    const waypoints: Array<L.LatLng> = [];

    // adds start location to route
    if (manifest.start_lat && manifest.start_lng) {
      const position = L.latLng(manifest.start_lat, manifest.start_lng);
      const marker = DASHBOARD_DOT_MARKER(position, paneName).addTo(map);

      if (dashboard) {
        marker.on('click', dashboard.toggleManifest);
      }

      mapItems.push(marker);
      waypoints.push(position);
      bounds.extend(position);
    }

    // adds order locations to route
    manifest.orders.filter(order => (
      order.id && (
        (
          (order.TypeId === EOrderTypes.Pickup || order.IsPickup === 1)
          && (order.pickup_lat && order.pickup_lng)
        ) || (
          !(order.TypeId === EOrderTypes.Pickup || order.IsPickup === 1)
          && (order.dest_lat && order.dest_lng)
        )
      )
    )).forEach((order, index) => {
      const isPickupNode = (
        order.TypeId === EOrderTypes.Pickup || order.IsPickup === 1
      );

      const position = (isPickupNode
        ? L.latLng(order.pickup_lat, order.pickup_lng)
        : L.latLng(order.dest_lat, order.dest_lng)
      );

      const marker = DASHBOARD_DOT_MARKER(
        position,
        paneName,
        this.textHelper.getStatusColorClass(order.StatusId),
        index + 1,
      ).bindTooltip(
        this.textHelper.generateOrderTooltip(order),
        {
          className: 'dark',
          direction: 'top',
          offset: [0, -8],
        },
      ).addTo(map);

      if (dashboard) {
        marker.on('click', dashboard.toggleManifest);

        const mapActions: IWaypointMapActions[keyof IWaypointMapActions] = {
          highlight: () => {
            const color = this.textHelper.getStatusColorClass(order.StatusId);
            const element = marker.getElement()?.children[0] as HTMLElement;
            element.style.boxShadow = `0 0 5px 5px ${color}`;
          },
          dehighlight: () => {
            const element = marker.getElement()?.children[0] as HTMLElement;
            element.style.boxShadow = 'none';
          },
        };

        dashboard.waypointMapActions[order.id] = Object.assign(
          {},
          dashboard.waypointMapActions[order.id],
          mapActions,
        );
      }

      mapItems.push(marker);
      waypoints.push(position);
      bounds.extend(position);
    });

    // adds finish location to route
    if (manifest.end_lat && manifest.end_lng) {
      const position = L.latLng(manifest.end_lat, manifest.end_lng);
      const marker = DASHBOARD_DOT_MARKER(position, paneName).addTo(map);

      if (dashboard) {
        marker.on('click', dashboard.toggleManifest);
      }

      mapItems.push(marker);
      waypoints.push(position);
      bounds.extend(position);
    }

    // adds route
    if (waypoints.length) {
      // @ts-ignore: leaflet plugin typings - build error
      const routing = L.Routing.control({
        // @ts-ignore: leaflet plugin typings - build error
        router: L.Routing.osrmv1({
          serviceUrl: 'API/routeopt/routeapi/driving?points='
        }),
        routeLine: (route) => {
          // @ts-ignore: leaflet plugin typings - build error
          const line = L.Routing.line(route, {
            styles: (dashboard
              ? [
                { pane: paneName, className: 'route-outer-line', color: 'black', opacity: 1, weight: 8 },
                { pane: paneName, color: 'white', opacity: 1, weight: 6 },
                { pane: paneName, color: colour, opacity: 1, weight: 3 },
              ]
              : [
                { color: 'white', opacity: 1, weight: 6 },
                { color: colour, opacity: 1, weight: 3 },
              ]
            ),
            addWaypoints: false,
          });
          if (dashboard) {
            line.eachLayer((layer) => layer.on('click', dashboard.toggleManifest));

            const mapActions: IManifestMapActions[keyof IManifestMapActions] = {
              highlight: () => {
                pane.classList.add('_hover');
              },
              dehighlight: () => {
                pane.classList.remove('_hover');
              },
              select: () => {
                pane.classList.add('selected');
                map.fitBounds(bounds);
              },
              unselect: () => {
                pane.classList.remove('selected');
              },
              show: () => {
                pane.classList.remove('_hide');
              },
              hide: () => {
                pane.classList.add('_hide');
              },
              remove: () => {
                line.remove();
                line.off();
                mapItems.forEach(item => {
                  item.remove();
                  item.off();
                });
                routing.remove();
                pane.remove();
                dashboard.manifestMapActions[manifest.id] = {};
              },
            };

            dashboard.manifestMapActions[manifest.id] = Object.assign(
              {},
              dashboard.manifestMapActions[manifest.id],
              mapActions,
            );

            // makes selected route always on top (need it due async route loads and reloads)
            dashboard.manifestMapActions[dashboard?.selected()]?.select?.();
          }
          return line;
        },
        containerClassName: 'not-shown',
        collapsible: true,
        autoRoute: false,
        fitSelectedRoutes: false,
        // @ts-ignore: leaflet plugin typings - build error
        plan: L.Routing.plan(waypoints, {
          createMarker: () => null,
          draggableWaypoints: false,
        }),
      });

      routing.addTo(map).route();
    }

    return bounds;
  }

  public generateOrderMapMarkers = (order: IOrderAny): Array<L.Marker> => {
    const markers = [];

    const bindOrderTooltip = (marker: L.Marker, pickup?: boolean): L.Marker => {
      return marker.bindTooltip(
        this.textHelper.generateOrderTooltip(order, pickup),
        {
          className: 'dark',
          direction: 'top',
          offset: [0, -12],
        },
      );
    };

    if (
      [EOrderTypes.Pickup, EOrderTypes.PickupDelivery].includes(order.TypeId)
      && (order.pickup_lat && order.pickup_lng)
    ) {
      const iconName = this.textHelper.getOrderIconByType(order, true, true);
      const marker = L.marker(L.latLng(order.pickup_lat, order.pickup_lng), {
        icon: ORDER_ICON(iconName),
        riseOnHover: true,
      });

      markers.push(bindOrderTooltip(marker, true));
    }

    if (
      order.TypeId !== EOrderTypes.Pickup
      && (order.dest_lat && order.dest_lng)
    ) {
      const iconName = this.textHelper.getOrderIconByType(order, true, false);
      const marker = L.marker(L.latLng(order.dest_lat, order.dest_lng), {
        icon: ORDER_ICON(iconName),
        riseOnHover: true,
      });

      markers.push(bindOrderTooltip(marker, false));
    }
    return markers;
  }

  public isModuleAvailable = (module: EModuleIDs): boolean => {
    const navigationItem = this.navigationRaw.find(n => n.id === module);
    return navigationItem ? navigationItem.visible : false;
  }

  public isExtModuleAvailable = (module: EModuleExtIDs): boolean => {
    const navigationItem = this.navigationRaw.find(n => n.id === module);
    return navigationItem ? navigationItem.visible : false;
  }

  public isModuleReadonly = (module: EModuleIDs): boolean => {
    const navigationItem = this.navigationRaw.find(n => n.id === module);
    return navigationItem ? navigationItem.readonly : true;
  }

  public isModuleOperational = (module: EModuleIDs): boolean => {
    const navigationItem = this.navigationRaw.find(n => n.id === module);
    return navigationItem ? (navigationItem.visible && !navigationItem.readonly) : false;
  }

  public linkToPage = (module: IModuleRoute, ID?: string | number): string => {
    const idString = (ID
      ? `/${ID}`
      : ''
    );

    return `/${MAIN_ROUTE}/${ROUTES[module]}${idString}`;
  }

  public goToPage = (
    module: IModuleRoute,
    ID?: string | number,
    params?: IRouteQueryParams,
  ) => {
    if (params) {
      return this.router.navigate([this.linkToPage(module, ID)], { queryParams: params });
    } else {
      return this.router.navigate([this.linkToPage(module, ID)]);
    }
  }

  public goToFirstAvailableModule = (): void => {
    let moduleID: IModuleRoute;

    this.navigation.some(module => {
      if (module.children) {
        module.children?.some(moduleChild => {
          if (moduleChild.visible) {
            moduleID = moduleChild.id as IModuleRoute;
            return true;
          } else {
            return false;
          }
        });
      }

      if (module.visible) {
        moduleID = module.id as IModuleRoute;
        return true;
      } else {
        return false;
      }
    });

    if (moduleID) {
      this.goToPage(moduleID);
    } else {
      this.notice.showSnackBar('No available modules', ETimers.XLong, true);
      this.router.navigate([MAIN_ROUTE]);
    }
  }

  public goToOnboarding = () => {
    return this.router.navigate([ONBOARDING_ROUTE]);
  }

  public goToContactPage = (): void => {
    this.router.navigateByUrl(CONTACT_PAGE);
  }

  public goToLogin = (): void => {
    this.router.navigate([LOGIN_ROUTE]);
  }

  public memorizePageSettings = (
    storageKey: EStorageProperties, pageSettings: IPageSettings
  ): void => {
    this.storageService.write<IPageSettings>(storageKey, pageSettings, true);
  }

  /** Use after getting data in data source */
  public applyPageSettings = (storageKey: EStorageProperties, paginator: MatPaginator): void => {
    const pageSettings = this.storageService.read<IPageSettings>(storageKey);
    if (paginator?.page?.emit && pageSettings) {
      paginator.pageIndex = pageSettings.pageCurrent;
      paginator.pageSize = pageSettings.pageSize;

      paginator.page.emit({
        pageSize: pageSettings.pageSize,
        pageIndex: pageSettings.pageCurrent,
        length: paginator.getNumberOfPages(),
      });
    }
  }

  public setPageSettingsV3 = (storageKey: EStorageProperties, paginator: MatPaginator): void => {
    const pageSettings = this.storageService.read<IPageSettings>(storageKey);
    paginator.pageIndex = pageSettings ? pageSettings.pageCurrent : 0;
    paginator.pageSize = pageSettings ? pageSettings.pageSize : 10;
  }

  public memorizeSortSettings = (
    storageKey: EStorageProperties, sortSettings: ISortSettings
  ): void => {
    this.storageService.write<ISortSettings>(storageKey, sortSettings, true);
  }

  /** Use before table rendering */
  public applySortSettings = (storageKey: EStorageProperties, sort: MatSort): void => {
    const sortSettings = this.storageService.read<ISortSettings>(storageKey);
    if (sort?.sort && sortSettings?.sortColumn && sortSettings?.sortDirection) {
      sort.sort({
        id: sortSettings.sortColumn,
        start: sortSettings.sortDirection,
        disableClear: false,
      });
    }
  }

  public setSortSettingsV3 = (storageKey: EStorageProperties, sort: MatSort): void => {
    const sortSettings = this.storageService.read<ISortSettings>(storageKey);
    if (sortSettings) {
      sort.active = sortSettings.sortColumn;
      sort.direction = sortSettings.sortDirection;
      sort.disableClear = false;
    }
  }

  public getPageSettings = <T>(paginator: MatPaginator, sort: MatSort, filters: T): ITableSettings<T> => {
    const sorting: ISortParams = {
      ...(sort.direction && sort.active ? { orderBy: sort.active } : {}),
      ...(sort.direction ? { orderDirection: sort.direction } : {}),
    };
    const limit = paginator.pageSize ?? 10;
    const offset = limit * (paginator.pageIndex ?? 0);
    const paging: IPagingParams = { limit, offset };

    return { sorting, paging, filters };
  }

  public showServerMessageStreamDialog = (
    data: IServerMessageStreamInput,
    afterCloseAction: (autoallocateResult?: IAutoallocateResult) => void,
    smallModal = false,
  ): void => {
    this.dialog.open<
      ServerMessageStreamDialogComponent, IServerMessageStreamInput, IAutoallocateResult
    >(ServerMessageStreamDialogComponent, {
      width: (smallModal ? '640px' : '95%'),
      maxWidth: '1280px',
      height: (smallModal ? undefined : '95%'),
      maxHeight: '98vh',
      autoFocus: false,
      data: data,
    }).afterClosed().subscribe(afterCloseAction);
  }

  public showConfirmDialog = (
    title: string,
    text: string,
    confirmAction: () => void,
  ): void => {
    this.dialog.open<ConfirmDialogComponent, IConfirmationInput, boolean>(ConfirmDialogComponent, {
      data: {
        title: title,
        text: text,
      },
      width: '600px',
      autoFocus: false,
    }).afterClosed().subscribe((res) => {
      if (res) {
        confirmAction();
      }
    });
  }

  private fetchImportInformation = (): void => {
    this.importInfoService.fetchImportHistory(res => {
      if (res?.length) {
        this.importInfoService.show();
        this.importInfoService.open();
        this.importInfoService.fetchImportHistoryByTimer();
      }
    });
  }
}
