<div class="dialog">
  <h1 mat-dialog-title class="dialog-title">
    Send message to driver
    <button mat-icon-button aria-label="close dialog" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </h1>

  <div mat-dialog-content class="dialog-content">
    <mat-form-field appearance="outline">
      <mat-label>Select the vehicle</mat-label>
      <mat-select
        placeholder="Select the vehicle"
        [(ngModel)]="selectedVehicleID"
      >
        <mat-option
          *ngIf="!vehiclesList"
          disabled
        >
          <span class="text-with-spinner">
            Loading... <mat-spinner [diameter]="20"></mat-spinner>
          </span>
        </mat-option>
        <mat-option
          *ngFor="let vehicle of vehiclesList"
          [value]="vehicle.id"
        >{{vehicle.name}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Message</mat-label>
      <textarea
        matInput
        placeholder="Message"
        cdkTextareaAutosize
        cdkAutosizeMinRows="6"
        [(ngModel)]="message"
      ></textarea>
    </mat-form-field>
  </div>

  <div mat-dialog-actions>
    <button
      mat-button
      class="mat-accent-300-bg"
      [disabled]="!(selectedVehicleID && message) || isApiLoading"
      (click)="sendMessageToVehicle()"
    >Send</button>
    <button
      mat-button
      class="mat-grey-200-bg"
      mat-dialog-close
    >Cancel</button>
  </div>
</div>
