import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeedbackCustomerFilterComponent } from './components/feedback-customer-filter/feedback-customer-filter.component';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule } from '@angular/forms';
import { DateRangeFilterModule } from '../date-range-filter/date-range-filter.module';
import { OpinionFilterComponent } from './components/opinion-filter/opinion-filter.component';
import { DriverFilterComponent } from './components/driver-filter/driver-filter.component';
import { OrderTypeFilterComponent } from './components/order-type-filter/order-type-filter.component';
import { FeedbackCustomerAllFilterComponent } from './components/feedback-customer-all-filter/feedback-customer-all-filter.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { OnlyWithCommentFilterComponent } from './components/only-with-comment-filter/only-with-comment-filter.component';

@NgModule({
  declarations: [
    FeedbackCustomerFilterComponent,
    OpinionFilterComponent,
    DriverFilterComponent,
    OrderTypeFilterComponent,
    FeedbackCustomerAllFilterComponent,
    OnlyWithCommentFilterComponent,
  ],
  exports: [
    FeedbackCustomerFilterComponent,
    OpinionFilterComponent,
    DriverFilterComponent,
    OrderTypeFilterComponent,
    FeedbackCustomerAllFilterComponent,
    OnlyWithCommentFilterComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatOptionModule,
    MatSelectModule,
    MatRadioModule,
    FormsModule,
    DateRangeFilterModule,
    MatCheckboxModule,
  ]
})
export class FeedbackCustomerFilterModule { }
