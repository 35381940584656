import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { IBaseExportParams, ISafetyInspectionBase, IVehicleExportParams } from '../models/interfaces';
import { QueryUtilsService } from './query-utils.service';
import { FeedbackRequestParams } from '../modules/analytics/shared/types/types';

@Injectable({
  providedIn: 'root'
})
export class ExportService {

  constructor(
    private queryUtilsService: QueryUtilsService
  ) { }

  private openWindow = (url: string): void => {
    window.open(url, '_blank');
  }

  public exportCustomer = (): void => {
    const xlsxURL = '/API/Clients/ExportClients/';
    this.openWindow(xlsxURL);
  }

  public exportInspection = (inspections: ISafetyInspectionBase[]): void => {
    const idsString = inspections.map((item: ISafetyInspectionBase) => item.Id).join('|');
    const xlsxURL = `/ReportViewer.aspx?report=InspectionReport.xlsx&ids=${idsString}`;
    this.openWindow(xlsxURL);
  }

  public exportVehicle = (params: IVehicleExportParams): void => {
    const endpoint = '/api/v3/vehicle/export';
    const xlsxURL = this.queryUtilsService.getPreparedQuery(endpoint, params);
    this.openWindow(xlsxURL);
  }

  public exportCheckItems = (params: IBaseExportParams): void => {
    const endpoint = '/api/v3/safetyItem/export';
    const xlsxURL = this.queryUtilsService.getPreparedQuery(endpoint, params);
    this.openWindow(xlsxURL);
  }

  public exportFeedback = (params: FeedbackRequestParams): void => {
    const endpoint = '/api/v3/analytics/feedback/export';
    const xlsxURL = this.queryUtilsService.getPreparedQuery(endpoint, params);
    this.openWindow(xlsxURL);
  }
}
