import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, Router, RouterStateSnapshot } from '@angular/router';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

import {
  ConfirmDialogComponent,
  IConfirmationInput,
} from 'app/main/content/_shared/confirm-dialog/confirm-dialog.component';

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean;
}

@Injectable()
export class UnsavedGuard implements CanDeactivate<ComponentCanDeactivate> {
  constructor(
    private location: Location,
    private router: Router,
    private dialog: MatDialog,
  ) { }

  canDeactivate(
    component: ComponentCanDeactivate,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot,
  ): boolean | Observable<boolean> {
    if (!component?.canDeactivate || component.canDeactivate()) {
      return true;
    } else {
      const dialogRef = this.dialog.open<ConfirmDialogComponent, IConfirmationInput, boolean>(ConfirmDialogComponent, {
        data: {
          title: 'You have unsaved changes',
          text: `Do you want to navigate away?`,
        },
        width: '600px',
        autoFocus: false,
      });

      return dialogRef.afterClosed().pipe(map((confirmation) => {
        // workaround for angular bug https://github.com/angular/angular/issues/13586#issuecomment-458241789
        if (!confirmation && this.router.getCurrentNavigation().trigger === 'popstate') {
          this.location.go(currentState.url);
        }
        return !!confirmation;
      }));
    }
  }
}
