<div class="dialog">
  <h1 mat-dialog-title class="dialog-title">
    {{ data.title }}
    <button mat-icon-button aria-label="close dialog" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </h1>

  <div mat-dialog-content class="dialog-content text">
    {{ data.text }}
  </div>

  <div mat-dialog-actions>
    <button
      mat-button
      [class]="confirmBtnClass"
      [mat-dialog-close]="!data.reverseBtn"
    >{{ confirmBtnText }}</button>
    <button
      mat-button
      [class]="cancelBtnClass"
      [mat-dialog-close]="data.reverseBtn"
    >{{ cancelBtnText }}</button>
  </div>
</div>
