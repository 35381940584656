<mat-radio-group
  [value]="(analyticFilterService.filter$ | async).feedbackRequestType"
  [disabled]="api.loading"
  (change)="onChangeFilterValue({ feedbackRequestType: $event.value })"
>
  <mat-radio-button class="mb-8" value="Recent">Most recent</mat-radio-button>
  <mat-radio-button class="mb-8" value="Positive">Positive</mat-radio-button>
  <mat-radio-button class="mb-8" value="Neutral">Neutral</mat-radio-button>
  <mat-radio-button class="mb-8" value="Negative">Negative</mat-radio-button>
</mat-radio-group>
