import { Component, OnInit } from '@angular/core';
import { ApiService } from 'app/services/api.service';
import { AnalyticsFilterService } from '../../../../services/analytics-filter.service';
import { IDriver } from 'app/models/interfaces';
import { map } from 'rxjs/operators';
import { FeedbackCustomerFilter } from '../../../../types/types';

@Component({
  selector: 'fuse-driver-filter',
  templateUrl: './driver-filter.component.html',
  styleUrls: ['./driver-filter.component.scss']
})
export class DriverFilterComponent implements OnInit {
  public driversList: IDriver[] = [];

  constructor(
    public api: ApiService,
    public analyticFilterService: AnalyticsFilterService,
  ) { }

  ngOnInit(): void {
    this.api.getAllDrivers().pipe(map((res) => res.body)).subscribe(drivers => {
      this.driversList = drivers;
    });
  }

  public onChangeFilterValue = (filter: FeedbackCustomerFilter): void => {
    this.analyticFilterService.extendFilter(filter);
  }

  public clearDriverOption = (event: Event): void => {
    event.stopPropagation();
    this.onChangeFilterValue({ driverId: null });
  }
}
