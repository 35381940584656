import { Component, OnInit } from '@angular/core';
import { ApiService } from 'app/services/api.service';
import { AnalyticsFilterService } from '../../../../services/analytics-filter.service';
import { FeedbackCustomerFilter } from '../../../../types/types';

@Component({
  selector: 'fuse-opinion-filter',
  templateUrl: './opinion-filter.component.html',
  styleUrls: ['./opinion-filter.component.scss']
})
export class OpinionFilterComponent implements OnInit {

  constructor(
    public analyticFilterService: AnalyticsFilterService,
    public api: ApiService,
  ) { }

  ngOnInit(): void {
  }

  public onChangeFilterValue = (filter: FeedbackCustomerFilter): void => {
    this.analyticFilterService.extendFilter(filter);
  }
}
