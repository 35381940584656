import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImportInfoService } from '../import-info.service';

export interface IImportDetailDialogInput {
  importId: number;
}

enum ELogType {
  Normal = 1,
  Error = 2,
}

@Component({
  selector: 'fuse-import-detail-dialog',
  templateUrl: './import-detail-dialog.component.html',
  styleUrls: ['./import-detail-dialog.component.scss']
})
export class ImportDetailDialogComponent implements OnInit, OnDestroy {
  public title = 'Import';
  public messages: { text: SafeHtml, class: string }[] = [];

  private timer: ReturnType<typeof setInterval>;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: IImportDetailDialogInput,
    private importInfoService: ImportInfoService,
    private domSanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.fetchImportDetail();
    });
    this.timer = setInterval(() => {
      this.fetchImportDetail();
    }, 10_000);
  }

  ngOnDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  public fetchImportDetail = (): void => {
    this.importInfoService.getImportDetail(this.data.importId).subscribe((data) => {
      this.title = `Import - ${data.typeName}`;
      this.messages = data.details.map((item) => ({
        text: this.domSanitizer.bypassSecurityTrustHtml(item.text),
        class: item.typeId === ELogType.Error ? 'error' : '',
      }));
    });
  }
}
