import { Component, } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { ApiService } from 'app/services/api.service';
import { IVehicle } from 'app/models/interfaces';
import { SharedService } from 'app/services/shared.service';
import { NoticeService } from 'app/services/notice.service';

@Component({
  templateUrl: './send-message-to-vehicle-dialog.component.html',
})
export class SendMessageToVehicleDialogComponent {
  public vehiclesList: Array<IVehicle>;
  public selectedVehicleID: number;
  public message: '';

  constructor(
    private api: ApiService,
    private sharedService: SharedService,
    private dialogRef: MatDialogRef<SendMessageToVehicleDialogComponent>,
    private notice: NoticeService,
  ) {
    this.api.getAllVehicles().subscribe(({ data }) => {
      this.vehiclesList = data;
    });
  }

  get isApiLoading(): boolean {
    return this.api.loading;
  }

  public sendMessageToVehicle = (): void => {
    this.api.sendMessageToVehicle(
      this.selectedVehicleID, this.message
    ).subscribe(() => {
      this.notice.showSnackBar('Message sent');
      this.dialogRef.close();
    });
  }

}
