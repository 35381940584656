import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { EFeedbackRequestType, EOrderType, FeedbackCustomerFilter } from '../types/types';
import { DateTimeService } from 'app/services/date-time.service';
import { EDatePresets } from '../../../../consts/enums';

@Injectable()
export class AnalyticsFilterService {
  private defaultFilter: FeedbackCustomerFilter;
  private filterSubject: BehaviorSubject<FeedbackCustomerFilter>;
  public filter$: Observable<FeedbackCustomerFilter>;

  constructor(
    private dateTime: DateTimeService,
  ) {
    this.defaultFilter = this.getDefaultFilter();
    this.filterSubject = new BehaviorSubject(this.defaultFilter);
    this.filter$ = this.filterSubject.asObservable();
  }

  public getDefaultFilter(): FeedbackCustomerFilter {
    const { fromDate, toDate } = this.dateTime.getDateRange(EDatePresets.LastThirtyDays);
    return {
      dateFrom: fromDate,
      dateTo: toDate,
      feedbackRequestType: EFeedbackRequestType.Recent,
      orderType: EOrderType.Delivery,
      offset: 0,
      limit: 10,
    };
  }

  public setDefaultFilter(): void {
    this.filterSubject.next(this.defaultFilter);
  }

  public setFilter(filter: any): void {
    this.filterSubject.next(filter);
  }

  public getFilter(): FeedbackCustomerFilter {
    return this.filterSubject.getValue();
  }
  public extendFilter(filter: FeedbackCustomerFilter, resetPager = true): void {
    const offset: FeedbackCustomerFilter = resetPager ? { offset: 0 } : {};
    this.setFilter({ ...this.getFilter(), ...offset, ...filter });
  }
}
