import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'fuse-rating-stars',
  templateUrl: './rating-stars.component.html',
  styleUrls: ['./rating-stars.component.scss']
})
export class RatingStarsComponent implements OnInit {
  @Input() rating: number;

  public items: string[] = [];

  constructor() { }

  ngOnInit(): void {
    this.items = Array.from({ length: 5 }, (v, i) =>  i < this.rating ? 'star_filled' : '');
  }

}
