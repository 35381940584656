import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fuse-feedback-customer-all-filter',
  templateUrl: './feedback-customer-all-filter.component.html',
  styleUrls: ['./feedback-customer-all-filter.component.scss']
})
export class FeedbackCustomerAllFilterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
